<template>
    <v-container fluid class="admin-page">
        <v-row class="justify-space-between">
          <v-col
          cols="7"
          >
              <h2>{{ formType.text }} Vendor</h2>
          </v-col>
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveVendor()" :loading="buttonLoading" :disabled="buttonLoading">
                  {{ formType.value == 'new' ? 'Save' : 'Update' }}
                  <template v-slot:loader>
                      <v-progress-circular
                          indeterminate
                          color="black"
                      ></v-progress-circular>
                  </template>
              </v-btn>
          </v-col>
        </v-row>
        <Alert />
        <v-row class="crystal-admin-form justify-space-around pt-5">
              <v-col cols="5">
                  <label>Company Name: </label>
                  <v-text-field
                      solo
                      placeholder="Company Name"
                      v-model="vendor.company_name"
                  ></v-text-field>
                  <label>First Name: </label>
                  <v-text-field
                      solo
                      placeholder="First Name"
                      v-model="vendor.first_name"
                  ></v-text-field>
                  <label>Middle Name: </label>
                  <v-text-field
                      solo
                      placeholder="Middle Name"
                      v-model="vendor.middle_name"
                  ></v-text-field>
                  <label>Last Name: </label>
                  <v-text-field
                      solo
                      placeholder="Last Name"
                      v-model="vendor.last_name"
                  ></v-text-field>
                  <label>Address: </label>
                  <v-textarea
                      solo
                      placeholder="Address"
                      v-model="vendor.VendorContact.address"
                  ></v-textarea>
                  <label>Country: </label>
                  <v-select
                      solo
                      :items="country.countries"
                      append-icon="mdi-magnify"
                      item-text="country"
                      item-value="country_code"
                      placeholder="Vendor Country"
                      v-model="vendor.VendorContact.country"
                      @change="stateFromCountry"
                  ></v-select>
                  <div v-if="state.length">
                      <label>State</label>
                      <v-select
                          solo
                          :items="state"
                          placeholder="Select State"
                          append-icon="mdi-magnify"
                          v-model="vendor.VendorContact.state"
                      >    
                      </v-select>
                  </div>
                  <div v-else-if="!state.length">
                      <label>State/Region/Prefecture</label>
                      <v-text-field
                          solo
                          placeholder="Insert State/Region/Prefecture"
                          v-model="vendor.VendorContact.state"
                      >    
                      </v-text-field>
                  </div>
                  <label>Unit: </label>
                  <v-text-field
                      solo
                      placeholder="Unit"
                      v-model="vendor.VendorContact.unit"
                  ></v-text-field>
              </v-col>
              <v-col cols="5">
                  <label>Phone Number: </label>
                  <v-text-field
                      solo
                      placeholder="Phone Number"
                      :prefix="dial_code + ' |'"
                      v-model="phone_number"
                      :disabled="dial_code == ''"
                  ></v-text-field>
                  <label>Postal Code: </label>
                  <v-text-field
                      solo
                      placeholder="Postal Code"
                      v-model="vendor.VendorContact.postal_code"
                  ></v-text-field>
                  <label>Email: </label>
                  <v-text-field
                      solo
                      placeholder="Enter your email"
                      v-model="vendor.VendorContact.email"
                  ></v-text-field>
                  <label>Gender: </label>
                  <v-row class="justify-start">
                      <v-radio-group
                          v-model="vendor.gender"
                          row
                      >
                          <v-radio label="Male" :value="0"></v-radio>
                          <v-radio label="Female" :value="1"></v-radio>
                      </v-radio-group>
                  </v-row>
                  <label>Bank Name: </label>
                  <v-text-field
                      solo
                      placeholder="Enter Bank Name"
                      v-model="vendor.VendorContact.bank_name"
                  ></v-text-field>
                  <label>Bank Account Number: </label>
                  <v-text-field
                      solo
                      placeholder="Enter bank account number"
                      v-model="vendor.VendorContact.account_number"
                  ></v-text-field>
                  <label>Bank Branch: </label>
                  <v-text-field
                      solo
                      placeholder="Enter bank branch"
                      v-model="vendor.VendorContact.bank_branch"
                  >
                  </v-text-field>
                  <label>Bank Account Name: </label>
                  <v-text-field
                      solo
                      placeholder="Enter bank account name"
                      v-model="vendor.VendorContact.account_name"
                  >
                  </v-text-field>
                  <label>Tax Status: </label>
                  <v-select
                      solo
                      placeholder="Enter Tax Status"
                      v-model="vendor.VendorContact.tax_status"
                      :items="tax_statuses"
                  >
                  </v-select>
              </v-col>
        </v-row>
        <v-row class="justify-end">
            <v-col cols="2">
                <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveVendor()" :loading="buttonLoading" :disabled="buttonLoading">
                  {{ formType.value == 'new' ? 'Save' : 'Update' }}
                  <template v-slot:loader>
                      <v-progress-circular
                          indeterminate
                          color="black"
                      ></v-progress-circular>
                  </template>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  import Alert from '@/components/Alert.vue'
  import countrydata from './country/country.json'
  export default {
      name: 'VendorForm',
      components: {
          Alert
      },
      props: {
          vendor: {
              type: Object,
              default: () => {
                  return {
                      company_name: '',
                      first_name: '',
                      middle_name: '',
                      last_name: '',
                      gender: 0,
                      VendorContact: {
                          email: '',
                          phone_number: '',
                          address: '',
                          country: '',
                          state: '',
                          unit: '',
                          account_name: '',
                          account_number: '',
                          bank_branch: '',
                          bank_name: '',
                          postal_code: '',
                          tax_status: '',
                          dial_code: '',
                      },
                  }
              }
          },
          formType: {
              type: Object,
              default: () => {
                  return {
                      text: 'Add New',
                      value: 'new'
                  }
              }
          },
          buttonLoading: {
              type: Boolean,
              default: true
          }
      },
      data () {
          return {
              tax_statuses: [
                  'PKP',
                  'Non PKP'
              ],
              country: countrydata,
              state : [],
          }
      },
      methods: {
          saveVendor() {
              this.$emit('saveVendor', this.dial_code);
          },
          stateFromCountry(item) {
              this.country.countries.forEach(country => {
                  if (country.country_code == item) {
                      this.state = country.states
                      this.dial_code = country.dial_code
                  }
              })
          },
      },
      computed: {
          dial_code: {
              get: function() {
                  return this.vendor.VendorContact.country ? this.getDialCode(this.vendor.VendorContact.country) : ""
              },
              set: function(v) {
                  return v;
              }
          },
          phone_number: {
              get: function () {
                  return this.vendor.VendorContact ? this.removeCountryCode(this.vendor.VendorContact.country, this.vendor.VendorContact.phone_number) : '';
              },
              set: function (value) {
                  this.vendor.VendorContact.phone_number = value;
              }
          },
      },
  }
  </script>
  
  <style>
  
  </style>
  