<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center" justify="space-around">
        <v-col
        cols="6"
        >
            <h2>Internal Stock Transfer</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Customer"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/stock">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add New Stock
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="3">
            <div class="px-3 crystal-admin-form">
                <label>Date</label>
                <v-text-field
                    placeholder="Choose Date"
                />
            </div>  
        </v-col>
        <v-col cols="3">
            <div class="px-3 crystal-admin-form">
                <label>From - Destination</label>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="From"
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="To"
                        />
                    </v-col>
                </v-row>
            </div>   
        </v-col>
        <v-col cols="3">
            <div class="px-3 crystal-admin-form" fill-height>
                <label>Type</label>
                <v-select
                    placeholder="Type"
                    :items="types"
                    v-model="filterType"
                ></v-select>
            </div>       
        </v-col>
        <v-col cols="3">
            <div class="px-3 crystal-admin-form" height="100%">
                <label>Stock</label>    
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="min"
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="max"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <Alert />
            <v-data-table
                :headers="headers"
                :items="internalTransfers"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        v-model="item.status"
                    ></v-switch>
                </template>
                <template v-slot:[`item.to_date`]="{ item }">
                    <td>{{ formatDate(item.to_date) }}</td>
                </template>
                <template v-slot:[`item.from`]="{ item }">
                    <td v-if="item.Vendor">{{ item.Vendor.company_name }}</td>
                    <td v-else-if="item.ShipFromLocation">{{ item.ShipFromLocation ? item.ShipFromLocation.LocationName : '' }}</td>
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                    <span v-for="(product, index) in item.InternalTransferLines.slice(0, 3)" :key="product.id">
                        {{ product.Item ? product.Item.name : '' }}{{` (x${product.qty})` }}{{ index === 2 ? `...${item.InternalTransferLines.length - 3} more` : index === item.InternalTransferLines.length-1 ? '' : ',' }}
                    </span>
                </template>
                <template v-slot:[`item.destination`]="{ item }">
                    <td v-if="item.ToVendor">{{ item.ToVendor.company_name }}</td>
                    <td v-else-if="item.ShipToLocation">{{ item.ShipToLocation ? item.ShipToLocation.LocationName : '' }}</td>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ formatDate(item.createdAt) }}
                </template>
                <template v-slot:[`item.type`]="{ item }">
                    <td v-if="item.type == 'inter_warehouse'">Inter Warehouse</td>
                    <td v-else-if="item.type == 'adjustment'">Adjustment</td>
                    <td v-else-if="item.type == 'purchases'">Purchases</td>
                    <td v-else-if="item.type == 'purchase_return'">Purchase Return</td>
                </template>
                <template v-slot:[`item.memo`]="{ item }">
                    <td>{{ item.InternalTransferLines[0] ? item.InternalTransferLines[0].memo : 'Lorem Ipsum' }}</td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small text class="mr-2" @click="getDetailInternalStock(item)">mdi-eye</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DeleteDialog 
        :message="`Remove Internal Stock ${internal_transfer.id} ?`"
        :item="internal_transfer"
        :showDelete="showDelete"
        @deleteItem="deleteStock"
        @closeDialog="closeDialog"
    />
    <DetailInternalStock
        :internal_stock="internal_transfer"
        :detail_internal_stock="detail_internal_stock"
        @closeDetail="closeDetail"
    />
  </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import server from '../api';
import DetailInternalStock from '@/components/DetailInternalStock.vue';

export default {
    name: 'Stock',
    components: {
        Alert,
        DeleteDialog,
        DetailInternalStock
    },
    data () {
        return {
            headers: [
                { text: 'Date', value: 'to_date'},
                { text: 'From', value: 'from'},
                { text: 'Destination', value: 'destination'},
                { text: 'Type', value: 'type'},
                { text: 'Qty', value: 'qty'},
                { text: 'Memo', value: 'memo'},
                { text: 'Action', value: 'actions' }
            ],
            internal_transfer: {},
            showDelete: false,
            types: [
                {
                    text: 'All',
                    value: ''
                },
                {
                    text: 'Purchases',
                    value: 'purchases'
                },
                {
                    text: 'Inter-Warehouse',
                    value: 'inter_warehouse'
                },
                {
                    text: 'Adjustment',
                    value: 'adjustment'
                },
                {
                    text: 'Purchase Return',
                    value: 'purchase_return'
                }
            ],
            filterType: '',
            detail_internal_stock: false
        }
    },
    methods: {
        showDeleteDialog(item) {
            this.internal_transfer = item;
            this.showDelete = !this.showDelete;
        },
        closeDialog () {
            this.showDelete = !this.showDelete;
        },
        closeDetail () {
            this.detail_internal_stock = !this.detail_internal_stock;
        },
        deleteStock (id) {
            this.showDelete = !this.showDelete;
            server.delete(`/internal_transfer/${id}`, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.dispatch('getInternalTransfer');
                    this.$store.commit('SET_SUCCESS', `Berhasil menghapus Internal Transfer ${result.data.InternalTransfer.id}`)
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message)
                });
        },
        getDetailInternalStock(item) {
            this.internal_transfer = item;
            this.detail_internal_stock = !this.detail_internal_stock;
        }
    },
    computed: {
        internalTransfers () {
            if (this.filterType) {
                return this.$store.state.internalTransfers.filter(it => it.type == this.filterType)
            } else {
                return this.$store.state.internalTransfers
            }
        }
    },
    created () {
        this.$store.dispatch('getInternalTransfer')
    }
}
</script>

<style>

</style>