<template>
  <SampleForm 
    :sample="sample"
    @saveSample="saveSample"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import server from '../api'
import SampleForm from '../components/SampleForm.vue'

export default {
    name: 'AddSample',
    components: {
        SampleForm
    },
    data () {
        return {
            sample: {
                DeliveryMethodId: 1,
                ShipStatus: '',
                postal_code: '',
                ShiptoAddress1: '',
                purpose: '',
                ShipFromLocationId: '',
                country: '',
                state: '',
                unit: '',
                SampleLine: {
                    items: []
                },
                shipment_date: '',
                memo: '',
            },
            buttonLoading: false,
        }
    },
    methods: {
        saveSample (recipient) {
            this.buttonLoading = !this.buttonLoading;
            if(!this.sample.SampleLine.items.length) {
                this.$store.commit('SET_ERROR', 'Anda belum menambahkan item')
                this.buttonLoading = !this.buttonLoading;
            } else {
                server.post('/samples', {
                    CustomerId: recipient.CustomerContact ? recipient.id : null,
                    ShipFromLocationId: this.sample.ShipFromLocationId,
                    ShiptoAddress1: this.sample.ShiptoAddress1,
                    purpose: this.sample.purpose,
                    items: this.sample.SampleLine.items,
                    status: 'payment_success',
                    ShipStatus: this.sample.ShipStatus,
                    VendorId: recipient.VendorContact ? recipient.id : null,
                    postal_code: this.sample.postal_code,
                    memo: this.sample.memo,
                    country: this.sample.country,
                    state: this.sample.state,
                    unit: this.sample.unit,
                }, {
                    headers: {
                        token: localStorage.token
                    }
                })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `berhasil menambahkan Sample ${result.data.Sample.id}`);
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', err.response.data.errors.message);
                });
            }
        },
    },
}
</script>

<style>
</style>