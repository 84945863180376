<template>
    <v-container fluid class="admin-page">
        <v-row class="justify-space-between">
          <v-col
          cols="7"
          >
              <h2>{{ formType.text }} Customer</h2>
          </v-col>
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveCustomer()" :loading="buttonLoading" :disabled="buttonLoading">
                  {{ formType.value == 'new' ? 'Save' : 'Update' }}
                  <template v-slot:loader>
                      <v-progress-circular
                          indeterminate
                          color="black"
                      ></v-progress-circular>
                  </template>
              </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
            <Alert />
            <Overlay
              :overlay="overlay"
            />
        </v-row>
        <v-row class="crystal-admin-form justify-space-around pt-5">
              <v-col cols="5">
                  <label>Company Name: </label>
                  <v-text-field
                      solo
                      placeholder="Company Name"
                      v-model="customer.company_name"
                  ></v-text-field>
                  <label>First Name: </label>
                  <v-text-field
                      solo
                      placeholder="First Name"
                      v-model="customer.firstName"
                  ></v-text-field>
                  <label>Middle Name: </label>
                  <v-text-field
                      solo
                      placeholder="Middle Name"
                      v-model="customer.middleName"
                  ></v-text-field>
                  <label>Last Name: </label>
                  <v-text-field
                      solo
                      placeholder="Last Name"
                      v-model="customer.lastName"
                  ></v-text-field>
                  <label>Email: </label>
                  <v-text-field
                      solo
                      placeholder="Enter Customer email"
                      v-model="customer.CustomerContact.email"
                  ></v-text-field>
                  <label>Gender: </label>
                  <v-row class="justify-start">
                      <v-radio-group
                          v-model="customer.CustomerContact.gender"
                          row
                      >
                          <v-radio label="Male" :value="0"></v-radio>
                          <v-radio label="Female" :value="1"></v-radio>
                      </v-radio-group>
                  </v-row>
                  <label>Invoice Address: </label>
                  <v-textarea
                      solo
                      placeholder="Address"
                      v-model="customer.CustomerContact.invoice_address"
                  ></v-textarea>
                  <label>Invoice Postal Code: </label>
                  <v-text-field
                      solo
                      placeholder="Postal Code"
                      v-model="customer.CustomerContact.invoice_postal_code"
                  ></v-text-field>
              </v-col>
              <v-col cols="5">
                  <label>Ship to Address: </label>
                  <v-textarea
                      solo
                      placeholder="Ship To Address"
                      v-model="customer.CustomerContact.addressLine1"
                  ></v-textarea>
                  <label>Country</label>
                  <v-select
                      solo
                      :items="country.countries"
                      placeholder="Select Country"
                      append-icon="mdi-magnify"
                      item-text="country"
                      item-value="country_code"
                      @change="stateFromCountry"
                      v-model="customer.CustomerContact.country"
                  >    
                  </v-select>
                  <div v-if="state.length">
                      <label>State</label>
                      <v-select
                          solo
                          :items="state"
                          placeholder="Select State"
                          append-icon="mdi-magnify"
                          v-model="customer.CustomerContact.state"
                      >    
                      </v-select>
                  </div>
                  <div v-else-if="!state.length">
                      <label>State/Region/Prefecture</label>
                      <v-text-field
                          solo
                          placeholder="Insert State/Region/Prefecture"
                          v-model="customer.CustomerContact.state"
                      >    
                      </v-text-field>
                  </div>
                  <label>Unit: </label>
                  <v-text-field
                      solo
                      placeholder="Unit"
                      v-model="customer.CustomerContact.unit"
                  ></v-text-field>
                  <label>Postal Code: </label>
                  <v-text-field
                      solo
                      placeholder="Postal Code"
                      v-model="customer.CustomerContact.postal_code"
                  ></v-text-field>
                  <label>Phone Number: </label>
                  <v-text-field
                      solo
                      placeholder="Phone Number"
                      :prefix="dial_code + ' |'"
                      v-model="customerContact"
                      :disabled="dial_code == ''"
                  ></v-text-field>
                  <label>Type: </label>
                  <v-select
                      solo
                      :items="customer_types"
                      label="Choose Type"
                      v-model="customer.type"
                  ></v-select>
                  <label>Tax Status: </label>
                  <v-select
                      solo
                      :items="tax_statuses"
                      label="Choose Status"
                      v-model="customer.taxStatus"
                  ></v-select>
              </v-col>
        </v-row>
        <v-row class="justify-end">
            <v-col cols="2">
                <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveCustomer()" :loading="buttonLoading" :disabled="buttonLoading">
                  {{ formType.value == 'new' ? 'Save' : 'Update' }}
                  <template v-slot:loader>
                      <v-progress-circular
                          indeterminate
                          color="black"
                      ></v-progress-circular>
                  </template>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  import Alert from '@/components/Alert.vue'
  import Overlay from '@/components/Overlay.vue'
  import countrydata from './country/country.json'
  
  export default {
      name: 'CustomerForm',
      components: {
          Alert,
          Overlay
      },
      props: {
          customer: {
              type: Object,
              default: () => {
                  return {
                      company_name: '',
                      firstName: '',
                      middleName: '',
                      lastName: '',
                      taxStatus: '',
                      type: '',
                      CustomerContact: {
                          addressLine1: '',
                          contactNo: '',
                          email: '',
                          invoice_address: '',
                          invoice_postal_code: '',
                          postal_code: '',
                          gender: 0,
                          country: '',
                          state: '',
                          unit: '',
                      }
                  }
              }
          },
          formType: {
              type: Object,
              default: () => {
                  return {
                      text: 'Add New',
                      value: 'new'
                  }
              }
          },
          buttonLoading: {
              type: Boolean,
              default: false
          },
          overlay: {
              type: Boolean,
              default: false
          }
      },
      data () {
          return {
              state: [],
              country: countrydata,
              tax_statuses: [
                  'PKP',
                  'Non PKP'
              ],
          }
      },
      computed: {
          dial_code: {
              get: function() {
                  return this.customer.CustomerContact.country ? this.getDialCode(this.customer.CustomerContact.country) : ""
              },
              set: function(v) {
                  return v;
              }
          },
          customerContact: {
              get () {
                  return this.removeCountryCode(this.customer.CustomerContact.country, this.customer.CustomerContact.contactNo);
              },
              set (value) {
                  this.customer.CustomerContact.contactNo = value[0] == '+' ? this.removeCountryCode(this.customer.CustomerContact.country, value) : value;
              }
          },
          customer_types () {
              return this.$store.state.customer_types;
          }
      },
      methods: {
          stateFromCountry(item) {
              this.country.countries.forEach(country => {
                  if (country.country_code == item) {
                      this.state = country.states
                      this.dial_code = country.dial_code
                  }
              })
          },
          saveCustomer() {
              this.$emit('saveCustomer', this.dial_code);
          }
      },
  }
  </script>
  
  <style>
  
  </style>