<template>
   <CustomerForm 
    :customer="customer"
    @saveCustomer="saveCustomer"
    :buttonLoading="buttonLoading"
   />
</template>

<script>
import server from '../api';
import CustomerForm from '@/components/CustomerForm.vue'

export default {
    name: 'AddCustomer',
    components: {
        CustomerForm
    },
    data () {
        return {
            customer: {
                company_name: '',
                firstName: '',
                middleName: '',
                lastName: '',
                taxStatus: '',
                type: '',
                CustomerContact: {
                    addressLine1: '',
                    contactNo: '',
                    email: '',
                    invoice_address: '',
                    postal_code: '',
                    invoice_postal_code: '',
                    gender: 0,
                    country: '',
                    state: '',
                    unit: '',
                }
            },
            buttonLoading: false
        }
    },
    
    methods: {
        saveCustomer (dial_code) {
            this.buttonLoading = !this.buttonLoading;
            server.post('/customers', {
                gender: this.customer.CustomerContact.gender,
                company_name: this.customer.company_name,
                firstName: this.customer.firstName,
                middleName: this.customer.middleName,
                lastName: this.customer.lastName,
                contactNo: dial_code + this.customer.CustomerContact.contactNo,
                email: this.customer.CustomerContact.email,
                taxStatus: this.customer.taxStatus,
                type: this.customer.type,
                addressLine1: this.customer.CustomerContact.addressLine1,
                postal_code: this.customer.CustomerContact.postal_code,
                invoice_address: this.customer.CustomerContact.invoice_address,
                invoice_postal_code: this.customer.CustomerContact.invoice_postal_code,
                country: this.customer.CustomerContact.country,
                state: this.customer.CustomerContact.state,
                unit: this.customer.CustomerContact.unit,
                
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Menambahkan Customer dengan Name ${result.data.Customer.firstName}`)
                    this.$router.push({ name: 'Customer' });
                })
                .catch(error => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', error.response.statusText)
                });
        }
    }
}
</script>

<style>

</style>