<template>
    <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col cols="7">
          <h2>{{ formType.text }} Promotion</h2>
        </v-col>
        <v-col cols="2">
          <v-btn
            type="submit"
            class="text--futura text--white text-capitalize"
            dark
            block
            @click.prevent="savePromotion()"
            :loading="buttonLoading"
            :disabled="buttonLoading"
          >
            {{ formType.value == "new" ? "Save" : "Update" }}
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                color="black"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-col>
      </v-row>
      <Alert />
      <v-row class="crystal-admin-form justify-space-around">
        <v-col cols="5">
          <label>Platform: </label>
          <v-select
            :items="sales_types"
            solo
            label="Choose Platform"
            v-model="promotion.platform"
          >
          </v-select>
          <label>Country: </label>
          <v-select
                      solo
                      :items="country.countries"
                      placeholder="Select Country"
                      append-icon="mdi-magnify"
                      item-text="country"
                      item-value="country_code"
                      @change="stateFromCountry"
                      v-model="promotion.countryCode"
                  > 
          </v-select>
          <label>Promotion Name: </label>
          <v-text-field
            solo
            placeholder="Promotion Name"
            v-model="promotion.promoName"
          ></v-text-field>
          <v-switch
            :label="
              promotion.promotionDefault
                ? 'Active Promotion Default'
                : 'Inactive Promotion Default'
            "
            v-model="promotion.promotionDefault"
            color="success"
          >
            Status Promotion Default
          </v-switch>
          <label>Validity Periods: </label>
          <v-row>
            <v-col cols="5" sm="6" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="promotion.valid_from"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="promotion.valid_from"
                    label="From"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="promotion.valid_from" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(promotion.valid_from)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="4">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="promotion.valid_until"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="promotion.valid_until"
                    label="To"
                    readonly
                    v-bind="attrs"
                    append-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="promotion.valid_until"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(promotion.valid_until)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <label>Description</label>
          <v-textarea
            solo
            placeholder="Description"
            v-model="promotion.description"
          ></v-textarea>
          <label>Terms: </label>
          <small>Promo can be used if</small>
          <v-select
            :items="terms_list"
            item-text="text"
            item-value="value"
            label="Terms"
            solo
            v-model="promotion.terms"
          ></v-select>
          <small>is</small>
          <v-row>
            <v-col cols="3">
              <v-select
                :items="promoConditions"
                label="Conditions"
                solo
                v-model="promotion.promoCondition"
                v-if="promotion.terms !== 'minTransaction'"
              ></v-select>
            </v-col>
            <v-col cols="8">
              <v-text-field
                solo
                placeholder="Enter Max Price"
                prefix="SGD | "
                v-model="promotion.maxAmount"
                v-if="
                  promotion.promoCondition == '<' && promotion.terms == 'price'
                "
              ></v-text-field>
              <v-text-field
                v-else-if="
                  promotion.promoCondition == '>' && promotion.terms == 'price'
                "
                solo
                placeholder="Enter Minimum Price"
                prefix="SGD | "
                v-model="promotion.amount"
              ></v-text-field>
              <v-text-field
                v-else-if="
                  promotion.promoCondition == '>' && promotion.terms == 'qty'
                "
                solo
                placeholder="Enter Minimum Quantity"
                prefix=""
                v-model="promotion.amount"
              ></v-text-field>
              <v-text-field
                v-else-if="
                  promotion.promoCondition == '<' && promotion.terms == 'qty'
                "
                solo
                placeholder="Enter Maximum Quantity"
                prefix=""
                v-model="promotion.maxAmount"
              ></v-text-field>
              <v-text-field
                v-else-if="promotion.terms == 'minTransaction'"
                solo
                placeholder="Enter Minimum Transactions"
                prefix="SGD | "
                v-model="promotion.minTransaction"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <label>Images</label><br />
          <v-row class="justify-space-between">
            <v-col cols="4">
              <div
                class="btn btn-upload d-flex justify-center align-center"
                @click="onButtonClick"
              >
                <img
                  id="preview1"
                  class="product-preview"
                  v-if="promotion.promoImage"
                  :src="promo_image"
                />
                <v-icon x-large v-if="!promotion.promoImage">mdi-plus</v-icon>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </div>
              <br />
            </v-col>
          </v-row>
          <label>Terms Condition</label>
          <vue-editor
            v-model="promotion.rule"
            :editorToolbar="customToolbar"
          ></vue-editor>
          <br />
          <label>Promotion Code: </label>
          <v-text-field
            solo
            placeholder="Promotion Code"
            v-model="promotion.promoCode"
          ></v-text-field>
          <label>Promo Type</label>
          <v-row class="justify-space-around">
            <v-radio-group v-model="promotion.promoType" row>
              <v-radio label="Percent" value="percent"></v-radio>
              <v-radio label="Amount" value="rounded"></v-radio>
              <v-radio label="Free Shipping" value="freeshipping"></v-radio>
            </v-radio-group>
          </v-row>
          <v-text-field
            solo
            v-model="promotion.promoValue"
            placeholder="Enter Promo Value"
            v-if="promotion.promoType !== 'freeshipping'"
            :prefix="promotion.promoType === 'rounded' ? 'SGD |' : ''"
            :append-icon="promotion.promoType === 'percent' ? 'mdi-percent' : ''"
          ></v-text-field>
          <label v-if="promotion.promoType === 'freeshipping' || promotion.promoType === 'percent'">Max Promo</label>
          <v-text-field
            solo
            v-model="promotion.maxAmount"
            placeholder="Enter Max Promo Value"
            v-if="promotion.promoType !== 'rounded'"
            :prefix="'SGD |'"
          ></v-text-field>            
          <v-row class="justify-space-around">
            <v-col cols="5">
              <label>Used Quota</label>
              <v-text-field
                solo
                placeholder="Enter Quota"
                v-model="promotion.usedQuota"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <label>Max Quota</label>
              <v-text-field
                type="number"
                solo
                placeholder="Enter Max Quota"
                v-model="promotion.Quota"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <label>Status</label>
          <v-switch
            :label="promotion.promoStatus ? 'Active' : 'Inactive'"
            v-model="promotion.promoStatus"
            color="success"
          >
            Status
          </v-switch>
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-col cols="2">
          <v-btn
            type="submit"
            class="text--futura text--white text-capitalize"
            dark
            block
            @click.prevent="savePromotion()"
            :loading="buttonLoading"
            :disabled="buttonLoading"
          >
            {{ formType.value == "new" ? "Save" : "Update" }}
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                color="black"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import $ from "jquery";
  import Alert from "@/components/Alert.vue";
  import { VueEditor } from "vue2-editor";
  import countrydata from './country/country.json'
  
  export default {
    name: "PromotionForm",
    components: {
      Alert,
      VueEditor,
    },
    props: {
      promotion: {
        type: Object,
        default: () => {
          return {
            valid_from: new Date().toISOString().substr(0, 10),
            valid_until: new Date().toISOString().substr(0, 10),
            promoType: "",
            promoName: "",
            promoCode: "",
            promoStatus: 1,
            platform: "",
            terms: "",
            promoCondition: "",
            amount: 0,
            maxAmount: 0,
            Quota: 0,
            usedQuota: 0,
            minTransaction: 0,
            promoValue: 0,
            promotionDefault: false,
            description: "",
            rule: "",
            promoImage: "",
          };
        },
      },
      formType: {
        type: Object,
        default: () => {
          return {
            text: "Add New",
            value: "new",
          };
        },
      },
      buttonLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        country: countrydata,
        promoConditions: ["<", ">"],
        terms_list: [
          {
            text: "Quantity",
            value: "qty",
          },
          {
            text: "Price",
            value: "price",
          },
          {
            text: "Minimum Transaction",
            value: "minTransaction",
          },
        ],
        menu: false,
        menu2: false,
        customToolbar: [
          [{ header: [false, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
      };
    },
    methods: {
      savePromotion() {
        this.$emit("savePromotion");
      },
      onFileChanged(e) {
        this.promotion.promoImage = e.target.files[0];
        if (e.target.files && e.target.files[0]) {
          var reader = new FileReader();
  
          reader.onload = function(event) {
            $("#preview1").attr("src", event.target.result);
          };
  
          reader.readAsDataURL(e.target.files[0]);
        }
      },
      onButtonClick() {
        this.$refs.uploader.click();
      },
    },
    computed: {
      sales_types() {
        return this.$store.state.sales_types;
      },
      promo_image() {
        if (
          this.promotion.promoImage &&
          typeof this.promotion.promoImage == "string"
        ) {
          return `${process.env.VUE_APP_API_URL}/${this.promotion.promoImage}`;
        } else {
          return "";
        }
      },
    },
  };
  </script>
  
  <style></style>
  