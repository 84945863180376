<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>Create Checks</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveProduct()">
                Submit
            </v-btn>
        </v-col>
      </v-row>
      <v-container fluid class="crystal-admin-form">
        <v-row class="justify-space-around">
                <v-col cols="5">
                    <v-row>
                        <v-col cols="6">
                            <label>Bank Account</label>
                            <v-text-field
                                solo
                                placeholder="Enter bank account"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <label>Ending Balance</label><br>
                            <span>{{ formatHarga(25000000000)}}</span>
                        </v-col>
                    </v-row>
                    <label>Pay to the Order Of: </label>
                    <v-text-field
                        solo
                        placeholder="Enter Pay Order"
                    ></v-text-field>
                    <label>Amount IDR</label>
                    <v-text-field
                        solo
                        placeholder="Enter amount"
                        prefix="SGD. |"
                    ></v-text-field>
                    <label>Address: </label>
                    <v-textarea
                        solo
                        placeholder="Address"
                    ></v-textarea>  
                </v-col>
                <v-col cols="5">
                    <label>Check Number</label>
                    <v-text-field
                        solo
                        placeholder="Enter check number"
                    >
                    </v-text-field>
                    <label>Date: </label>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="order_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="order_date"
                                    label="Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    solo
                                    append-icon="mdi-calendar"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="order_date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(order_date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <label>Memo</label>
                    <v-textarea
                        solo
                        placeholder="Enter Customer Name"
                        append-icon="mdi-magnify"
                    >    
                    </v-textarea>
                    <label>Class</label>
                    <v-text-field
                        solo
                        placeholder="Enter Class"
                    >
                    </v-text-field>
                </v-col>
        </v-row>
        <v-row>
            <v-col cols="1">
                <v-btn
                    rounded
                    :dark="tab == 'tab1'"
                    class="text-capitalize"
                    @click.prevent="setTab('tab1')"
                >
                    Expense
                </v-btn>
            </v-col>
            <v-col cols="1">
                <v-btn
                    rounded
                    :dark="tab == 'tab2'"
                    class="text-capitalize"
                    @click.prevent="setTab('tab2')"
                >
                    Items
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="tab == 'tab1'">
                <v-data-table
                    dense
                    :headers="headersExpense"
                >
                </v-data-table>
            </v-col>
            <v-col cols="12" v-if="tab == 'tab2'">
                <v-data-table
                    dense
                    :headers="headersItems"
                >
                </v-data-table>
            </v-col>
        </v-row>
      </v-container>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveProduct()">
                Submit
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'AddCheck',
    data () {
        return {
            menu: '',
            tab: 'tab1',
            order_date: '',
            headersExpense: [
                { text: 'Account', value: 'account'},
                { text: 'Amount', value: 'amount'},
                { text: 'Memo', value: 'memo'},
                { text: 'Customer Job', value: 'customer_job'},
                { text: 'Billable', value: 'billable'},
                { text: 'Class', value: 'class'}
            ],
            headersItems: [
                { text: 'Item', value: 'item'},
                { text: 'Description', value: 'description'},
                { text: 'Qty', value: 'quantity'},
                { text: 'Cost', value: 'cost'},
                { text: 'Amount', value: 'amount'},
                { text: 'Customer Job', value: 'customer_job'},
                { text: 'Billable', value: 'billable'},
                { text: 'Class', value: 'class'}
            ]
        }
    },
    methods: {
        saveProduct () {
        },
    }
}
</script>

<style>
</style>