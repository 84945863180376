<template>
    <v-container fluid class="admin-page">
        <v-row class="align-center" justify="space-around">
          <v-col
          cols="6"
          >
              <h2>Orders</h2>
          </v-col>
          <v-col
          cols="6">
              <v-row>
                  <v-col
                  cols="6"
                  >   
                      <v-text-field
                          label="Search Order Id"
                          append-icon="mdi-magnify"
                          solo
                          class="shrink"
                          clearable
                          dense
                          v-model="filterOrder"
                          @keydown.enter="searchOrder"
                      >
                      </v-text-field>
                  </v-col>
                  <v-col cols="2" class="fill-height">
                      <v-btn>
                          <v-icon>mdi-history</v-icon>
                      </v-btn>
                  </v-col>
                  <v-col cols="4">
                      <router-link to="/dashboard/add/order">
                          <v-btn class="text--futura text--white text-capitalize" dark block>
                              Add New Order
                          </v-btn>
                      </router-link>
                  </v-col>    
              </v-row>
          </v-col>
      </v-row>
      <v-row class="justify-space-around">
          <v-col cols="2">
              <div class="pa-3 crystal-admin-form">
                  <label>Date</label>
                  <v-row>
                      <v-col
                      cols="12"
                      >
                          <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="filterDate"
                              transition="scale-transition"
                              offset-y
                              min-width="350px"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="filterDateText"
                                  label="Choose Date"
                                  readonly
                                  v-bind="attrs"
                                  append-icon="mdi-calendar"
                                  clearable
                                  hide-details
                                  v-on="on"
                              ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="filterDate"
                                  no-title
                                  scrollable
                                  range
                              >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="menu = false"
                              >
                                  Cancel
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu.save(filterDate)"
                              >
                                  OK
                              </v-btn>
                              </v-date-picker>
                          </v-menu>
                      </v-col>
                  </v-row>
              </div>
          </v-col>
          <v-col cols="2">
              <div class="pa-3 crystal-admin-form">
                  <label>Channel</label>
                  <v-select
                      label="Choose Channel"
                      :items="sales_types"
                      v-model="filterChannel"
                  >
                  </v-select>
              </div>     
          </v-col>
          <v-col cols="2">
              <div class="pa-3 crystal-admin-form">
                  <label>Customer</label>
                  <v-text-field
                      placeholder="Customer"
                      v-model="filterCustomer"
                      @keydown.enter="searchCustomer"
                  >
                  </v-text-field>
              </div>   
          </v-col>
          <v-col cols="2">
              <div class="pa-3 crystal-admin-form">
                  <label>Ship From</label>
                  <v-select
                      label="Choose Warehouse"
                      :items="warehouses"
                      item-text="LocationName"
                      item-value="id"
                      v-model="filterWarehouse"
                      clearable
                  >
                  </v-select>
              </div>
          </v-col>
          <v-col cols="2">
              <div class="pa-3 crystal-admin-form">
                  <label>Shipment Status</label>
                  <v-select
                      label="Shipment Status"
                      :items="shipment_statuses"
                      v-model="filterShipStatus"
                      clearable
                  >
                  </v-select>
              </div>  
          </v-col>
          <v-col cols="2">
              <div class="pa-3 crystal-admin-form">
                  <label>Status</label>    
                  <v-select
                      label="Payment Status"
                      :items="statusFilters"
                      v-model="filterStatus"
                  >
                  </v-select>
              </div>
          </v-col>
      </v-row>
      <v-row class="mt-2">
          <Alert />
          <v-col
              cols="12"
          >
              <v-data-table
                  :headers="headers"
                  :items="orders"
                  :server-items-length="totalOrders"
                  :options="pagination"
                  @update:options="paginate"
                  :footer-props="{
                      'items-per-page-options': listSize,
                  }"
                  @update:items-per-page="getItemPerPage"
                  class="elevation-1"
              >
                  <template v-slot:[`item.order_date`]="{ item }">
                      <td>{{ formatDate(item.order_date) }}</td>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                      <v-chip
                          v-if="item.DeliveryHistory && item.DeliveryHistory.iscancel"
                          color="yellow"
                          text-color="black"
                      >
                          Shipping Cancelled
                      </v-chip>
                      <v-chip
                          v-else-if="item.OrderStatus == 'payment_pending'"
                      >
                          Payment Pending
                      </v-chip>
                      <v-chip
                          v-else-if="item.OrderStatus == 'payment_success'"
                          color="blue"
                          text-color="white"
                      >
                          Payment Success
                      </v-chip>
                      <v-chip
                          v-else-if="item.OrderStatus == 'canceled'"
                          color="red"
                          text-color="white"
                      >
                          Canceled
                      </v-chip>
                      <v-chip
                          v-else-if="item.OrderStatus == 'order_placed'"
                          color="green"
                          text-color="white"
                      >
                          Order Placed
                      </v-chip>
                  </template>
                  <template v-slot:[`item.Customer`]="{ item }">
                      {{ item.Customer ? item.Customer.fullName : "-" }}
                  </template>
                  <template v-slot:[`item.grand_total`]="{ item }">
                      {{ formatHarga(item.OrderLine.grand_total) }}
                  </template>
                  <template v-slot:[`item.sales_type`]="{ item }">
                      {{ item.sales_type ? capitalizeFirstLetter(item.sales_type) : '' }}
                  </template>
                  <template v-slot:[`item.product`]="{ item }">
                      <span v-for="(product, index) in item.OrderLine.items.slice(0, 2)" :key="product.id">
                          {{ product.Item ? product.Item.name : '' }}{{` (x${product.qty})` }}{{ index === 1 && item.OrderLine.items.length - 2 != 0 ? `...${item.OrderLine.items.length - 2} more` : index === item.OrderLine.items.length-1 ? '' : ',' }}
                      </span>
                  </template>
                  <template v-slot:[`item.ship_from`]="{ item }">
                      {{item.DeliveryHistory ? item.DeliveryHistory.pickUnit : (item.ShipFromLocation ? item.ShipFromLocation.addressLine1 : "-")}}
                  </template>
                  <template v-slot:[`item.ship_to`]="{ item }">
                      {{item.ShiptoAddress}}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small text @click="getDetailOrder(item)">mdi-eye</v-icon>
                  </template>
              </v-data-table>
          </v-col>
      </v-row>
      <DetailOrder 
          :order="selectedOrder"
          :detail_order="detail_order"
          @updateOrder="updateOrder"
          @closeDetail="closeDetail"
          :couriers="couriers"
          :courierLoading="courierLoading"
          @payOrder="payOrder"
          :loadingShip="loadingShip"
          @checkDeliveryPayment="checkDeliveryPayment"
          @checkDeliveryAwb="checkDeliveryAwb"
      />
    </v-container>
  </template>
  
  <script>
  import DetailOrder from '@/components/DetailOrder.vue'
  import Alert from '@/components/Alert.vue'
  import server from '../api'
  
  export default {
      name: 'Order',
      components: {
          DetailOrder,
          Alert
      },
      data () {
          return {
              loadingShip: false,
              couriers: [],
              courierLoading: false,
              menu: false,
              detail_order: false,
              selectedOrder: {},
              headers: [
                  { text: 'Date', value: 'order_date'},
                  { text: 'Channel', value: 'sales_type'},
                  { text: 'Customer', value: 'Customer'},
                  { text: 'Product', value: 'product'},
                  { text: 'Ship From', value: 'ship_from'},
                  { text: 'Ship To', value: 'ship_to' },
                  { text: 'Total', value: 'grand_total'},
                  { text: 'Status', value: 'status'},
                  { text: 'Action', value: 'actions'}
              ],
              statusFilters: [
                  {
                      text: 'All',
                      value: 'all'
                  },
                  {
                      text: 'Payment Pending',
                      value: 'payment_pending'
                  },
                  {
                      text: 'Payment Success',
                      value: 'payment_success'
                  },
                  {
                      text: 'Canceled',
                      value: 'canceled'
                  },
                  {
                      text: 'Order Completed',
                      value: 'order_placed'
                  }
              ],
              filterOrder: this.$route.query.order || '',
              filterStatus: this.$route.query.status || 'all',
              filterCustomer: this.$route.query.customer || '',
              listSize: [15, 25, 50, 100, -1],
              pagination: {
                  descending: !!this.$route.query.desc,
                  itemsPerPage: +this.$route.query.per_page || 15,
                  page: +this.$route.query.page || 1,
                  totalItems: this.totalOrders,
              },
              filterChannel: this.$route.query.sales_type || '',
              filterDate: [],
              filterWarehouse: +this.$route.query.ship_from || '',
              filterShipStatus: this.$route.query.shipment_status || ''
          }
      },
      beforeRouteUpdate(to, from, next) {
          this.fetchData(to);
          next();
      },
      watch: {
          filterChannel (val) {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              let same = true;
              obj.sales_type = val;
              for (let key in obj) {
                  if (query[key] != obj[key]) {
                      same = false;
                      break;
                  }
              }
              if (same) this.fetchData(); // page has been manually reloaded in the browser
              else {
                  this.$router.replace({
                      ...this.$router.currentRoute,
                      query: obj
                  });
              }
          },
          filterStatus (val) {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              obj.status = val;
              if (val === 'all') delete obj.status;
              this.$router.replace({
                  ...this.$router.currentRoute,
                  query: obj
              });
          },
          filterDate (val) {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              obj.start_date = val[0] ? val[0] : '';
              obj.end_date = val[1] ? val[1] : '';
              if (!obj.start_date) delete obj.start_date;
              if (!obj.end_date) delete obj.end_date;
              this.$router.replace({
                  ...this.$router.currentRoute,
                  query: obj
              });
          },
          filterWarehouse (val) {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              obj.ship_from = val;
              if (!val) delete obj.ship_from;
              this.$router.replace({
                  ...this.$router.currentRoute,
                  query: obj
              });
          },
          filterShipStatus (val) {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              obj.shipment_status = val;
              if (!val) delete obj.shipment_status;
              this.$router.replace({
                  ...this.$router.currentRoute,
                  query: obj
              });
          },
          filterOrder (val) {
              if (!val) {
                  this.searchOrder();
              }
          }
      },
      methods: {
          checkDeliveryPayment(orderNumber) {
              server.get(`/delivery/status/order?OrderId=${orderNumber}`)
              .then(res => {
                  this.detail_order = false
                  if (res.data.order_payable == "False") {
                      this.$store.dispatch('getOrders', this.$route.query);
                      this.$store.commit('SET_SUCCESS', `Order ${orderNumber} has already been paid`);
                  } else {
                      this.$store.commit('SET_ERROR', `Order ${orderNumber} hasn't been paid yet`);
                  } 
              })
              .catch(err => {
                  this.detail_order = false
                  if (err.response) {
                      if (err.response.data.errors) {
                          if (err.response.data.errors.message.remarks) {
                              this.$store.commit('SET_ERROR', err.response.data.errors.message.remarks);
                          } else {
                              this.$store.commit('SET_ERROR', err.response.data.errors.message);
                          }
                      } else {
                          this.$store.commit('SET_ERROR', err.response.data);
                      }
                  } else {
                      this.$store.commit('SET_ERROR', err);
                  }
              })
              this.$store.dispatch('getOrders', this.$route.query);
          },
          checkDeliveryAwb(orderNumber) {
              server.get(`/delivery/status/parcel?OrderId=${orderNumber}`)
              .then(res => {
                  this.detail_order = false
                  if (res.status == 200) {
                      if (res.data.parcel[0].awb != "") {
                          this.$store.dispatch('getOrders', this.$route.query);
                          this.$store.commit('SET_SUCCESS', `Order ${orderNumber} awb has been updated`);
                      } else {
                          this.$store.commit('SET_ERROR', `Order ${orderNumber} awb hasn't updated`);
                      }
                  }
              })
              .catch(err => {
                  this.detail_order = false
                  if (err.response) {
                      if (err.response.data.errors) {
                          if (err.response.data.errors.message.remarks) {
                              this.$store.commit('SET_ERROR', err.response.data.errors.message.remarks);
                          } else {
                              this.$store.commit('SET_ERROR', err.response.data.errors.message);
                          }
                      } else {
                          this.$store.commit('SET_ERROR', err.response.data);
                      }
                  } else {
                      this.$store.commit('SET_ERROR', err);
                  }
              })
              this.$store.dispatch('getOrders', this.$route.query);
          },
          payOrder(orderNumber) {
              this.loadingShip = true
              server.post(`/delivery/pay`, {
                  order_no: orderNumber,
              }, {
                  headers: {
                      token: localStorage.token
                  }
              })
              .then(res => {
                  this.$store.commit('SET_SUCCESS', res.data);
                  this.detail_order = !this.detail_order;
                  this.loadingShip = false
              })
              .catch(err => {
                  this.loadingShip = false
                  this.detail_order = !this.detail_order;
                  if (err.response) {
                      if (err.response.data.errors) {
                          this.$store.commit('SET_ERROR', err.response.data.errors.message);
                      } else {
                          this.$store.commit('SET_ERROR', err.response.data);
                      }
                  } else {
                      this.$store.commit('SET_ERROR', err);
                  }
              })
              this.$store.dispatch('getOrders', this.$route.query);
          },
          getItemPerPage(val) {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              obj.per_page = +val;
              this.$router.replace({
                  ...this.$router.currentRoute,
                  query: obj
              });
          },
          searchCustomer () {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              obj.customer = this.filterCustomer;
              if (this.filterCustomer === '') delete obj.customer;
              this.$router.replace({
                  ...this.$router.currentRoute,
                  query: obj
              });
          },
          searchOrder () {
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              obj.order = this.filterOrder;
              if (this.filterOrder === '') delete obj.order;
              this.$router.replace({
                  ...this.$router.currentRoute,
                  query: obj
              });
          },
          shipby(id) {
              this.courierLoading = true
              server.get(`/delivery/couriers?OrderId=${id}`)
              .then(res => {
                  res.data[0].DeliveryRate.forEach(rate => {
                      if (rate.service_detail == "pickup") {
                          this.couriers.push(rate)
                      }
                  })
                  this.courierLoading = false
              })
              .catch(err => {
                  this.$store.commit('SET_ERROR', err.response.data);
                  this.courierLoading = false
              })
          },
          getDetailOrder(item) {
              this.detail_order = true
              this.selectedOrder = item;
              Promise.all(this.selectedOrder.OrderLine.items.map(async (order) => {
                  let item = await server.get(`/products/${order.ItemId}`);
                  return {
                      ...order,
                      gross_weight: item.data.Product.gross_weight
                  }
              })).then((data) => {
                  this.selectedOrder.OrderLine.items = data;
                  this.shipby(item.id)
                  this.detail_order = true
              });
          },
          updateOrder(id, shipment) {
              this.loadingShip = true
              server.post(`/delivery`, {
                  OrderId: id,
                  service_id: shipment.service_id,
                  ShipFromLocationId: this.selectedOrder.ShipFromLocationId,
                  collect_date: shipment.datePicked,
                  courier_name: shipment.shipBy
              }, {
                  headers: {
                      token: localStorage.token
                  }
              })
              .then(result => {
                  server.post(`/delivery/pay`, {
                      order_no: result.data.orderNumber,
                  }, {
                      headers: {
                          token: localStorage.token
                      }
                  })
                  .then(result => {
                      this.detail_order = !this.detail_order;
                      this.loadingShip = false
                      this.$store.commit('SET_SUCCESS', result.data.message);
                  })
                  .catch(err => {
                      this.detail_order = false;
                      if (err.response) {
                          if (err.response.data.errors) {
                              this.$store.commit('SET_ERROR', err.response.data.errors.message);
                          } else {
                              this.$store.commit('SET_ERROR', err.response.data);
                          }
                      } else {
                          this.$store.commit('SET_ERROR', err);
                      }
                  });
              })
              .then(() => {
                  this.loadingShip = false
                  this.detail_order = false;
              })
              .catch(error => {
                  this.loadingShip = false
                  this.$store.commit('SET_ERROR', error.response.data.remarks ? error.response.data.remarks : error.response.data);
                  this.detail_order = !this.detail_order;
              })
              this.$store.dispatch('getOrders', this.$route.query);
          },
          closeDetail () {
              this.detail_order = !this.detail_order;
          },
          fetchData (route) {
              this.$store.dispatch('getOrders', route.query);
          },
          paginate (val) {
              // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
              const query = this.$route.query;
              const obj = Object.assign({}, query);
              if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
              if (val.descending) obj.desc = 'true';
              else delete obj.desc;
              obj.orderby = val.sortBy;
              obj.page = val.page;
              obj.per_page = val.itemsPerPage;
              // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
              let same = true;
              for (let key in query) {
                  if (query[key] != obj[key]) {
                      same = false;
                      break;
                  }
              }
              // to handle the case when a KEY exists in OBJ but not in query
              for (let key in obj) {
                  if (query[key] != obj[key]) {
                      same = false;
                      break;
                  }
              }
              if (same) this.fetchData(); // page has been manually reloaded in the browser
              else {
                  this.$router.replace({
                      ...this.$router.currentRoute,
                      query: obj
                  });
              }
          },
      },
      computed: {
          orders () {
              return this.$store.state.orders;
          },
          totalOrders () {
              return this.$store.state.totalOrders;
          },
          filterDateText: {
              get () {
                  return this.filterDate.join(' - ');
              },
              set () {
                  this.filterDate = [];
              }
          },
          warehouses () {
              return this.$store.state.warehouses;
          },
          sales_types () {
              return this.$store.state.sales_types;
          },
          shipment_statuses () {
              return this.$store.state.shipment_statuses;
          }
      },
      created () {
          this.$store.dispatch('getOrders', this.$route.query);
          this.$store.dispatch('getWarehouses');
      }
  }
  </script>
  
  <style>
  
  </style>