<template>
    <v-dialog
        v-model="detail_order"
        @click:outside="closeDetail"
    >
        <v-card>
            <v-card-title>
                {{ detail_type.text }} Details {{ order.id }}
                <v-spacer></v-spacer>   
                <v-chip color="primary">
                    {{ order.status }}
                </v-chip>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="justify-space-around">
                        <v-col cols="3">
                            <h4 class="text--futura">Date</h4><br>
                            <span class="text--futura">
                                {{ formatDate(order.createdAt) }}
                            </span>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Recipient</h4><br>
                            <span class="text--futura" v-if="order.Customer">
                                {{ order.Customer.firstName }} {{ order.Customer.middleName ? order.Customer.middleName : '' }} {{ order.Customer.lastName }}
                            </span>
                            <span class="text--futura" v-else-if="order.Vendor">
                                {{ order.Vendor.company_name }}
                            </span>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Contact</h4><br>
                            <span class="text--futura" v-if="order.Customer">
                                {{ order.Customer.CustomerContact.contactNo }}
                            </span>
                            <span class="text--futura" v-else-if="order.Vendor">
                                {{ order.Vendor.VendorContact.phone_number }}
                            </span>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Destination</h4><br>
                            <span class="text--futura">
                                {{ `${ order.ShipFromLocation ? order.ShipFromLocation.LocationName : "-" } - ${order.ShiptoAddress1} (${order.postal_code})` }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <h4 class="text--futura">Product</h4>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Quantity</h4>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Price Each</h4>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Price</h4>
                        </v-col>
                    </v-row>
                    <hr>
                    <div v-if="order.SampleLine">
                        <v-row v-for="product in order.SampleLine.items" :key="product.id">
                        <v-col cols="3">
                            <span class="text--futura">{{ product.Item.name }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="text--futura">{{ product.qty }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="text--futura">{{ formatHarga(0) }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="text--futura">{{ formatHarga(sub_total(0, 0)) }}</span>
                        </v-col>
                    </v-row>
                    </div>
                    <hr>
                    <hr>
                    <v-row class="justify-space-around">
                        <v-col cols="4">
                            <h4 class="text--futura">Shipment Address</h4><br>
                            <span class="text--futura">
                                {{ order.ShiptoAddress1 }}
                            </span>
                        </v-col>
                        <v-col cols="4">
                            <h4 class="text--futura">Ship By</h4><br>
                            <span class="text--futura">
                                {{order.DeliveryMethod ? order.DeliveryMethod.CourierCompany : "-" }}
                            </span>
                        </v-col>
                        <v-col cols="4">
                            <h4 class="text--futura">Purpose</h4><br>
                            <span class="text--futura">
                                {{ order.purpose ? capitalizeFirstLetter(order.purpose) : "-" }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="justify-space-around">
                        <v-col cols="3">
                            <h4 class="text--futura">Shipping Status</h4><br>
                            <v-select
                                solo
                                label="Shipping Status"
                                :items="shipment_statuses"
                                item-text="label" 
                                item-value="value"
                                v-model="order.ShipStatus"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Input Courier</h4><br>
                            <v-select
                                solo
                                label="Choose Courier"
                                :items="couriers"
                                item-text="CourierCompany"
                                item-value="id"
                                v-model="order.DeliveryMethodId"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Courier Tracking Code</h4><br>
                            <v-text-field
                                solo
                                placeholder="Courier Tracking Code"
                                v-model="order.tracking_code"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Payment Status</h4><br>
                            <v-select
                                solo
                                label="Set Status"
                                :items="order_statuses"
                                v-model="order.status"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="12">
                            <h4>Payment to Account</h4>
                            <v-select
                                solo
                                label="Enter Account">
                            </v-select>
                        </v-col>
                    </v-row> -->
                    <v-row class=" justify-end">
                        <!-- <v-btn class="text--futura text-capitalize mr-3">
                            <v-icon>
                                mdi-printer
                            </v-icon>
                        </v-btn> -->
                        <v-btn class="text--futura text-capitalize mr-3" @click="closeDetail">
                            Cancel {{ detail_type.text }}
                        </v-btn>
                        <router-link
                            :to="`/dashboard/edit/sample/${order.id}`"
                        >
                            <v-btn
                                dark
                                class="text--futura text-capitalize mr-3"
                            >
                                Edit {{ detail_type.text }}
                            </v-btn>
                        </router-link>
                        <v-btn
                            dark
                            class="text--futura text-capitalize mr-3"
                            @click.prevent="shippingLabel()"
                        >
                            Generate Shipping Label
                        </v-btn>
                        <v-btn
                            dark
                            class="text--futura text-capitalize mr-3"
                            @click.prevent="updateSample(order.id)"
                        >
                            Save
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import jsPDF from 'jspdf'

export default {
    name: 'DetailSample',
    props: {
        detail_order: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
            default: () => {
                return {
                    DeliveryMethodId: 1,
                    ShipStatus: '',
                    ShiptoAddress1: '',
                    purpose: '',
                    ShipFromLocationId: '',
                    SampleLine: {
                        items: []
                    },
                    ShipFromLocation: {},
                    DeliveryMethod: {},
                }
            }
        },
        detail_type: {
            type: Object,
            default: () => {
                return {
                    text: 'Sample',
                    value: 'sample'
                }
            }
        }
    },
    computed: {
        shipment_statuses () {
            return this.$store.state.shipment_statuses;
        },
        order_statuses () {
            return this.$store.state.order_statuses;
        },
        OrderStatus: {
            get: function () {
                return this.order.status
            },
            set: function(value) {
                this.order.status = value
            }
        },
        tracking_code: {
            get: function () {
                return this.order.tracking_code
            },
            set: function(value) {
                this.order.tracking_code = value
            }
        },
        ShipStatus: {
            get: function () {
                return this.order.ShipStatus
            },
            set: function(value) {
                this.order.ShipStatus = value
            }
        },
        couriers () {
            return this.$store.state.couriers
        }
    },
    methods: {
        closeDetail () {
            this.$emit('closeDetail');
        },
        sub_total(price, qty) {
            return price * qty;
        },
        shippingLabel() {
            let pdfName = `Shipping-Label-${this.order.id}`
            let doc = new jsPDF('p', 'mm', [150, 110]);
            //console.log(doc.getFontList())
            //doc.addFont('helvetica', "bold");
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.text(`Order No. ${this.order.id}`, 5, 10);
            doc.text(`Kurir. ${this.order.DeliveryMethod.CourierCompany}`, 32, 10);
            doc.text(`Resi No. ${this.order.tracking_code ? this.order.tracking_code : ''}`, 52, 10, { maxWidth: 100 });
            doc.setFont('helvetica', 'bold');
            doc.text(`Kepada`, 5, 20);
            doc.text(`Dari`, 70, 20);
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.text(`${this.order.ShiptoAddress1}`, 5, 30, { maxWidth: 50 });

            if (this.order.Customer) {
                doc.text(`${this.order.Customer.fullName}`, 5, 25);
                // doc.text(`${this.order.Customer.CustomerContact.addressLine1}`, 5, 40);
                //doc.text(`${this.order.Customer.CustomerContact.addressLine2}`, 5, 45);
                doc.text(`${this.order.Customer.CustomerContact.contactNo}`, 5, 80);
            } else if(this.order.Vendor) {
                doc.text(`${this.order.Vendor.company_name}`, 5, 25);
                // doc.text(`${this.order.Vendor.VendorContact.address}`, 5, 40);
                //doc.text(`${this.order.Customer.CustomerContact.addressLine2}`, 5, 45);
                doc.text(`${this.order.Vendor.VendorContact.phone_number}`, 5, 80);
            }

            doc.text(`Crystal of the Sea`, 70, 25);
            doc.text(`${this.order.ShipFromLocation.LocationName ? this.order.ShipFromLocation.LocationName : ''}`, 70, 30);
            //doc.text(`${this.order.Customer.CustomerContact.addressLine2}`, 50, 45)
            doc.text(`6281212128780`, 70, 80);
            doc.line(5,85,105,85);
            doc.setFont('helvetica', 'bold');
            doc.text(`SKU`, 60, 90);
            doc.text(`Jumlah`, 75, 90);
            doc.setFont('helvetica', 'normal');
            const itemList = this.order.SampleLine.items;
            var itemCount = Object.keys(itemList).length;
            //console.log(itemCount);
            var spacing = 5;
            if (itemCount > 6) {
                spacing = 3
                doc.setFontSize(6);
            }
            for (var i=0;i<itemCount;i++){
                //console.log(itemList[i].Item.name);
                doc.text(itemList[i].Item.name, 5, 95 + i*spacing, { maxWidth: 50 });
                doc.text(itemList[i].Item.sku, 60, 95 + i*spacing);
                doc.text(`${itemList[i].qty}`, 75, 95 + i*spacing);
                let width = doc.getTextWidth(itemList[i].Item.name);
                if (width > 50)  spacing = 10;
            }
            //doc.text(`${this.order.OrderLine.items[0].Item.name}`, 5, 75)
            doc.rect(3, 3, 105,145)
            doc.save(pdfName + '.pdf')
        },
        updateSample (id) {
            this.$emit('updateSample', id);
        },
    },
    created () {
        this.$store.dispatch('getCouriers');
    }

}
</script>

<style>

</style>