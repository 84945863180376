<template>
  <v-container fluid class="admin-page">
    <v-row class="justify-space-between">
      <v-col cols="7">
        <h2>Edit Invoice</h2>
      </v-col>
    </v-row>
    <v-container fluid class="crystal-admin-form">
      <InvoiceForm
        form_type="edit"
        :loading="loading"
        :loadingGet="loadingGetInvoice"
        :invoice="invoice"
        @deleteProductForm="deleteProductForm"
        @addProductForm="addProductForm"
        @saveInvoice="saveInvoice"
      ></InvoiceForm>
      <v-row justify="center">
        <v-alert color="green" type="success" v-if="successMessage">
          {{ successMessage }}
        </v-alert>
        <v-alert color="red" type="error" v-if="errorMessage">
          {{ errorMessage }}
        </v-alert>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import server from "../api";

import "vue2-datepicker/index.css";
import InvoiceForm from "../components/InvoiceForm.vue";

export default {
  name: "EditInvoice",
  components: {
    InvoiceForm,
  },
  data() {
    return {
      invoice: {
        order_id: "",
        type: "",
        memo: "",
        invoice_date: "",
        items: [],
      },
      dataDetailInvoice: {
        customer: {
          CustomerContact: {},
        },
        invoice_items: [],
      },
      loading: false,
      loadingGetInvoice: false,
    };
  },
  computed: {
    dataInvoice() {
      return this.dataDetailInvoice;
    },
    errorMessage () {
      return this.$store.state.errorMessage
    },
    successMessage () {
      return this.$store.state.successMessage
    }
  },
  watch: {
    dataInvoice(val) {
      let invoice_order = JSON.parse(JSON.stringify(val));
      this.invoice.order_id = invoice_order.order_id;
      this.invoice.type = invoice_order.type;
      this.invoice.memo = invoice_order.memo;
      this.invoice.invoice_date = invoice_order.invoice_date;
      this.invoice.items = invoice_order.invoice_items.reduce(
        (pre, current) => {
          pre = [
            ...pre,
            {
              id: current.Item.id,
              qty: current.qty,
              price: current.price,
            },
          ];
          return pre;
        },
        []
      );
    },
  },
  methods: {
    saveInvoice() {
      this.loading = true;
      let data = {};
      data = {
        order_id: this.invoice.order_id,
        type: this.invoice.type,
        memo: this.invoice.memo,
        invoice_date: this.invoice.invoice_date,
        items: JSON.parse(JSON.stringify(this.invoice.items)),
      };
      server
        .put(`/consignment_invoices/${this.$route.params.id}`, data, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((result) => {
          this.$store.commit("SET_SUCCESS", result.data.message);
          setTimeout(() => {
            this.$router.push({
              path: `/dashboard/add/invoice/?id_order=${result.data.order_id}`,
            });
          }, 1000);
          this.loading = false;
        })
        .catch((error) => {
          if(error.response.data.errors.message) {
            this.$store.commit("SET_ERROR", error.response.data.errors);
          } else {
            this.$store.commit("SET_ERROR", error.response.data.errors);
          }
          this.loading = false;
        });
    },
    getInvoice(invoice_id) {
      this.loadingGetInvoice = true;
      server
        .get(`/consignment_invoices/${invoice_id}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then(({ data }) => {
          this.dataDetailInvoice = data;
          this.loadingGetInvoice = false;
        })
        .catch((error) => {
          this.$store.commit("SET_ERROR", error.response.data);
          this.loadingGetInvoice = false;
        });
    },
    //add or delete form product
    addProductForm() {
      this.invoice.items.push({
        id: null,
        qty: null,
        price: null,
      });
    },
    deleteProductForm(index) {
      this.invoice.items.splice(index, 1);
    },
  },
  created() {
    this.getInvoice(this.$route.params.id);
  },
};
</script>

<style>
.date-picker > div > input.mx-input {
  height: 48px;
  border-color: transparent;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>
