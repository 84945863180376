<template>
  <RetailForm
    :retail="retail"
    @saveRetail="saveRetail"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import server from "../api";
import RetailForm from "@/components/RetailForm.vue";

export default {
  name: "AddRetailPartner",
  data() {
    return {
      retail: {
        customerId: null,
        displayName: '',
        displayPhone: '',
        address: '',
        longtitude: '',
        latitude: '',
        website: '',
        whatsappLink: '',
        retailType: '',
        media: '',
        operasionalHour: '',
      },
      buttonLoading: false,
    };
  },
  components: {
    RetailForm,
  },
  methods: {
    async saveRetail() {
        try {
            this.buttonLoading = !this.buttonLoading
            let data = new FormData();
            Object.keys(this.retail).forEach(key => data.append(key, this.retail[key]));
            
            const res = await server.post("/retailer_locations", data, {
                headers: {
                    token: localStorage.token,
                }
            })
            this.buttonLoading = !this.buttonLoading;
            this.$store.commit(
                "SET_SUCCESS",
                `Success add data location retail  ${res.data.Retailer.displayName}`
            )
            this.$router.push({name: "RetailPartner"})
        } catch (err) {
            this.buttonLoading = !this.buttonLoading;
            this.$store.commit("SET_ERROR", err.response.data.errors.message); 
        }
    },
  },
};
</script>

<style></style>
