<template>
  <RetailForm
    @saveRetail="UpdateRetail"
    :type="formType"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import server from "../api";
import RetailForm from "@/components/RetailForm.vue";

export default {
  name: "EditRetailPartner",
  data() {
    return {
      formType: {
        text: "Edit",
        value: "edit",
      },
      buttonLoading: false,
    };
  },
  components: {
    RetailForm,
  },
  methods: {
    async UpdateRetail(retaildata) {
      try {
        this.buttonLoading = !this.buttonLoading;
        let data = new FormData();
        Object.keys(retaildata).forEach(key => {
          data.append(key, retaildata[key])
        });
        
        const res = await server.put(`/retailer_locations/${this.retail.id}`, data, {
          headers: {
            token: localStorage.token,
          },
        });
        this.buttonLoading = !this.buttonLoading;
        this.$store.commit(
          "SET_SUCCESS",
          `Success Update data location retail ${res.data.Retailer.displayName}`
        );
        this.$router.push({ name: "RetailPartner" });
      } catch (err) {
        this.buttonLoading = !this.buttonLoading;
        this.$store.commit("SET_ERROR", err.response.data.errors.message);
      }
    },
  },
  computed: {
    retail() {
      return this.$store.state.detailRetail
    }
  },
  created () {
    this.$store.dispatch('getRetailLocation', { id: this.$route.params.id });
  }
};
</script>

<style></style>
