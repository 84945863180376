<template>
  <v-container fluid class="admin-page">
    <v-row class="justify-space-between">
      <v-col cols="7">
        <h2>{{ type.text }} Faq</h2>
      </v-col>
      <v-col cols="2">
      </v-col>
    </v-row>
    <v-row justify="center">
      <Alert />
    </v-row>
    <v-row class="justify-end">
      <v-col cols="2">
        <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveFaq()"
          :loading="buttonLoading" :disabled="buttonLoading">
          {{ type.value == "new" ? "Save" : "Update" }}
          <template v-slot:loader>
            <v-progress-circular indeterminate color="black"></v-progress-circular>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="crystal-admin-form justify-space-around pt-5">
      <v-col cols="10">
        <label>Faq Title</label>
        <v-text-field solo placeholder="Enter Faq Title" v-model="faq.title"></v-text-field>
        <label>Faq Body</label>
        <v-textarea solo placeholder="Enter Faq Body" v-model="faq.body">
        </v-textarea>
        <label>Faq Category</label>
        <v-select solo :items="categories" item-text="name" item-value="id" label="Choose Category"
          append-icon="mdi-magnify" v-model="faq.faq_category_id"></v-select>
        <label>Locale</label>
        <v-radio-group v-model="faq.locale" row>
          <v-radio label="en_US" value="en_US"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alert from "@/components/Alert.vue";
import server from "../api";

export default {
  name: "FaqForm",
  components: {
    Alert,
  },
  data() {
    return {
      categories: [],
      locales: ["en_US"]
    }
  },
  props: {
    faq: {
      type: Object,
      default: () => {
        return {
          title: "",
          body: "",
          faq_category_id: "",
          locale: ""
        };
      },
    },
    type: {
      type: Object,
      default: () => {
        return {
          text: "Add New",
          value: "new",
        };
      },
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveFaq() {
      this.$emit("saveFaq");
    },
    getCategories() {
      server.get("/faq_categories", { headers: { token: localStorage.token } })
        .then(response => this.categories = response.data.rows)
        .catch(err => this.$store.commit('SET_ERROR', err.response.data.errors[0].message))
    },
  },
  created() {
    this.getCategories()
  }
};
</script>

<style></style>
