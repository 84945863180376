<template>
  <CustomerForm 
    :customer="customer"
    @saveCustomer="updateCustomer"
    :formType="type"
    :buttonLoading="buttonLoading"
    :overlay="overlay"
  />
</template>

<script>
import CustomerForm from '@/components/CustomerForm.vue'
import server from '../api'

export default {
    name: 'EditCustomer',
    data () {
        return {
            type: {
                text: 'Edit',
                value: 'edit'
            },
            buttonLoading: false,
        }
    },
    components: {
        CustomerForm
    },
    computed: {
        customer () {
            return this.$store.state.customer
        },
        overlay () {
            return this.$store.state.overlay
        }
    },
    methods: {
        updateCustomer (dial_code) {
            this.buttonLoading = !this.buttonLoading;
            if (!this.customer.CustomerContact.contactNo.includes(dial_code)) {
                this.customer.CustomerContact.contactNo = dial_code + this.customer.CustomerContact.contactNo
            }
            server.put(`/customers/${this.customer.id}`, this.customer, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Memperbarui Customer ${result.data.Customer.firstName} ${result.data.Customer.lastName}`);
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    if(err.response.data.errors.length) {
                        this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                    } else {
                        this.$store.commit('SET_ERROR', err.response.data.errors.message);
                    }
                })
        }
    },
    created () {
        this.$store.dispatch('getCustomerById', { id: this.$route.params.id })
    }
}
</script>

<style>

</style>