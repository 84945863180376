<template>
  <v-container fluid class="admin-page">
    <v-row align="center" justify="space-around">
      <v-col cols="6">
        <h2>Inventory</h2>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Search Product, SKU"
              append-icon="mdi-magnify"
              solo
              class="shrink"
              clearable
              dense
              v-model="searchQuery"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" class="fill-height">
            <v-btn @click.prevent="refreshData()">
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <router-link to="/dashboard/add/product">
              <v-btn
                class="text--futura text--white text-capitalize"
                dark
                block
              >
                Add Product
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <v-col cols="5" class="crystal-input">
        <label class="text--futura">Status</label>
        <v-select
          :items="items"
          item-text="text"
          item-value="value"
          label="Active, Inactive and Draft"
          v-model="filterStatus"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <Alert />
        <v-data-table
          :headers="headers"
          :items="products"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.retail_price`]="{ item }">
            <td>{{ formatHarga(item.retail_price) }}</td>
          </template>
          <template v-slot:[`item.net_weight`]="{ item }">
            <td>{{ item.net_weight ? item.net_weight : 0 }} gr</td>
          </template>
          <template v-slot:[`item.ItemAssemblyLine.qty`]="{ item }">
            <td>{{ countStock(item.ItemAssemblyLines) }}</td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small text @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DeleteDialog
      :message="`Remove Item ${item.name} ?`"
      :item="item"
      :showDelete="showDelete"
      @deleteItem="deleteProduct"
      @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import server from "../api";
import DeleteDialog from "../components/DeleteDialog.vue";
import Alert from "../components/Alert.vue";
export default {
  name: "Inventory",
  components: {
    DeleteDialog,
    Alert,
  },
  data() {
    return {
      filterStatus: "",
      items: [
        {
          text: "All",
          value: "",
        },
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
        {
          text: "Draft",
          value: 2,
        },
      ],
      headers: [
        {
          text: "SKU",
          align: "start",
          sortable: true,
          value: "sku",
        },
        { text: "Product Name", value: "name" },
        { text: "Price", value: "retail_price" },
        { text: "Stock", value: "ItemAssemblyLine.qty" },
        { text: "Net Weight", value: "net_weight" },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      showDelete: false,
      item: {},
      searchQuery: "",
    };
  },
  created() {
    this.$store.dispatch("getProducts");
  },
  computed: {
    products() {
      if (this.filterStatus !== "") {
        let status = this.filterStatus;
        return this.$store.state.products.filter(
          (product) => product.status == status
        );
      } else if (this.searchQuery !== "" && this.searchQuery !== null) {
        return this.$store.state.products.filter((product) =>
          product.name.toLowerCase().includes(this.searchQuery)
        );
      } else {
        return this.$store.state.products;
      }
    },
  },
  methods: {
    countStock(arr) {
      let stock = 0;
      for (let i = 0; i < arr.length; i++) {
        stock += arr[i].qty;
      }
      return stock;
    },
    refreshData() {
      this.$store.dispatch("getProducts");
    },
    updateItem(item) {
      this.item = item;
      this.item.bundle_items = JSON.stringify(this.item.bundle_items);
      server
        .put(`/products/${item.id}`, this.item, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((result) => {
          this.$store.dispatch("getProducts");
          this.$store.commit(
            "SET_SUCCESS",
            `Updated Products ${result.data.Product.name} status menjadi ${
              result.data.Product.status ? "Active" : "Inactive"
            }`
          );
        })
        .catch((err) => {
          this.$store.commit("SET_ERROR", err.response.data.errors[0].message);
        });
    },
    showDeleteDialog(item) {
      this.item = item;
      this.showDelete = true;
    },
    deleteProduct(id) {
      this.showDelete = false;
      server
        .delete(`/products/${id}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((result) => {
          this.$store.dispatch("getProducts");
          this.$store.commit(
            "SET_SUCCESS",
            `Berhasil menghapus Products ${result.data.Product.name}`
          );
        })
        .catch((err) => {
          this.$store.commit("SET_ERROR", err.response.data.errors[0].message);
        });
    },
    closeDialog() {
      this.showDelete = false;
    },
    editItem(item) {
      this.item = item;
      this.$router.push({ path: `/dashboard/edit/product/${this.item.id}` });
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>