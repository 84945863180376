<template>
  <v-row justify="center" align="center">
      <v-col :cols="type == 'order' ? 4 : 6" class="pt-1">
            <label>Product Name: </label>
                <v-autocomplete
                    return-object
                    :items="products"
                    v-model="localProduct"
                    solo
                    placeholder="Product name"
                    item-text="name"
                    @input="updateProduct()"
                    hide-details="auto"
                ></v-autocomplete>
        </v-col>
        <v-col :cols="type == 'order' ? 2 : 3" class="pt-1">
            <label>Quantity</label>
            <v-text-field
                v-model="qty"
                solo
                placeholder="Enter Product Quantity"
                @change="updateProduct()"
                prefix="Qty |"
                hide-details="auto"
            >
            </v-text-field>
        </v-col>
        <v-col cols="2" v-if="type == 'order'" class="pt-1">
            <label>Price Each</label>
            <v-text-field
                v-model="price"
                placeholder="Enter Product Price"
                solo
                prefix="SGD |"
                @change="updateProduct()"
                hide-details="auto"
            >
            </v-text-field>
        </v-col>
        <v-col cols="2" class="px-0 py-0">
            <v-row align="center">
                <v-col cols="9" class="pt-1" v-if="type =='order'">
                    <label>Price</label>
                    <v-text-field
                        v-model="price_total"
                        solo
                        prefix="SGD |"
                        disabled
                        hide-details="auto"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3" class="mt-4">
                    <v-btn
                        fab
                        dark
                        small
                        v-if="index !== 0"
                        @click.prevent="deleteProduct(index)"
                    >
                        <v-icon dark small>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
  </v-row>
</template>

<script>
export default {
    name: 'ProductOrder',
    props: {
        index: {
            type: Number
        },
        type: {
            type: String,
            default: 'order'
        },
        formType: {
            type: Object,
            default: () => {
                return {
                    text: 'Add New',
                    value: 'new'
                }
            }
        },
        product: {
            type: Object,
            default: () => {
                return {
                    Item: {}
                }
            }
        }
    },
    computed: {
        price_total () {
            let total = this.qty * this.price ? this.qty * this.price : 0;
            return total;
        },
        products () {
            return this.$store.state.products
        },
        localProduct: {
            get: function () {
                return this.product.Item;
            },
            set: function (value) {
                this.product.Item = value;
            }
        },
        price: {
            get () {
                return this.product.price ? this.product.price : this.localProduct.retail_price
            },
            set (value) {
                if(value === '') value = this.localProduct.retail_price
                this.product.price = typeof value == "string" ? parseFloat(value) : value
            }
        },
        qty: {
            get: function () {
                return this.product.qty;
            },
            set: function (value) {
                this.product.qty = value;
            }
        }
    },
    methods: {
        deleteProduct(index) {
            this.$emit('deleteProduct', index)
        },
        updateProduct() {
            this.$emit('updateProduct', this.index, this.localProduct.id, this.qty, this.price)
        }
    },
    created () {
        this.$store.dispatch('getProducts')
    }
}
</script>

<style>

</style>