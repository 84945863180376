<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>{{ type.text }} Courier</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveCourier()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ type.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
          <Alert />
      </v-row>
      <v-row class="crystal-admin-form justify-space-around pt-5">
            <v-col cols="5">
                <label>Enter Courier Parent: </label>
                <v-select
                    solo
                    :items="couriers"
                    item-text="CourierCompany"
                    item-value="id"
                    label="Choose Courier"
                    append-icon="mdi-magnify"
                    v-model="courier.DeliveryMethodId"
                ></v-select>
                <label>Service Name </label>
                <v-text-field
                    solo
                    placeholder="Service Name"
                    v-model="courier.code"
                ></v-text-field>
                <label>Courier Type: </label>
                <v-row class="justify-start">
                    <v-radio-group
                        v-model="courier.deliveryType"
                        row
                    >
                        <v-radio label="Domestic" value="0"></v-radio>
                        <v-radio label="International" value="1"></v-radio>
                    </v-radio-group>
                </v-row>
                <label>Origins: </label>
                <v-autocomplete
                    solo
                    placeholder="Origins"
                    v-model="courier.origins"
                    :items="cities"
                    item-text="name"
                    item-value="city_id"
                >
                    <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.type }} {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.type }} {{ data.item.name }}
                    </template>
                </v-autocomplete>
                <label>Destination: </label>
                <v-autocomplete
                    solo
                    placeholder="Destination"
                    v-model="courier.destination"
                    :items="cities"
                    item-text="name"
                    item-value="city_id"
                >
                    <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.type }} {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.type }} {{ data.item.name }}
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="5">
                <label>Cost: </label>
                <v-text-field
                    solo
                    placeholder="Enter Courier Price"
                    prefix="SGD |"
                    v-model="courier.fees"
                >
                </v-text-field>
                <label>Unit Cost: </label>
                <v-text-field
                    solo
                    placeholder="Enter courier Price"
                    prefix="Kg |"
                    v-model="courier.unitFees"
                >
                </v-text-field>
                <label>Tracking Website: </label>
                <v-text-field
                    solo
                    placeholder="Insert the Tracking Website"
                    v-model="courier.website"
                ></v-text-field>
                <label>Status</label>
                <v-switch
                :label="courier.status ? 'Active' : 'Inactive'"
                v-model="courier.status"
                color="success">
                    Status
                </v-switch>
            </v-col>
      </v-row>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveCourier()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ type.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue'

export default {
    name: 'CourierForm',
    components: {
        Alert
    },
    props: {
        courier: {
            type: Object,
            default: () => {
                return {
                    status: 1,
                    DeliveryMethodId: '',
                    code: '',
                    deliveryType: '',
                    fees: '',
                    unitFees: '',
                    origins: '',
                    destination: '',
                    website: ''
                }
            }
        },
        type: {
            type: Object,
            default: () => {
                return {
                    text: 'Add New',
                    value: 'new'
                }
            }
        },
        buttonLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        couriers () {
            return this.$store.state.couriers
        },
        cities () {
            return this.$store.state.cities
        },
        provinces () {
            return this.$store.state.provinces
        }
    },
    methods: {
        saveCourier () {
            this.$emit('saveCourier')
        }
    },
    created () {
        this.$store.dispatch('getCouriers');
        this.$store.dispatch('getCities');
        this.$store.dispatch('getProvinces');
    }
}
</script>

<style>

</style>