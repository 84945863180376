<template>
    <v-navigation-drawer
    app
    dark
    :permanent="sidebarMenu"
    :mini-variant.sync="mini"
    > 
        <v-list-item class="px-2" @click.prevent="toggleMini()">
                <v-list-item-icon>
                    <v-img
                    width="50"
                    height="50"
                    src="../assets/Crystal_Logo_SVG.svg"
                    />
                </v-list-item-icon>
        </v-list-item>
        
        <v-list
        dense
        class="navbar-list"
        >
        <v-list-item
          dense
          v-for="item in items"
          :key="item.title"
          link :to="item.href"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text--futura"> {{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'Navbar',
    data () {
      return {
        sidebarMenu: true,
        mini: true, 
        items: [
          { title: 'Inventory', href: '/dashboard/inventory', icon: 'mdi-file-document mdi-18px' },
          // { title: 'Delivery', href: '/dashboard/delivery', icon: 'mdi-truck mdi-18px' },
          { title: 'Warehouse', href: '/dashboard/warehouse', icon: 'mdi-truck mdi-18px' },
          { title: 'Customers List', href: '/dashboard/customers', icon: 'mdi-account mdi-18px' },
          { title: 'Vendors List', href: '/dashboard/vendors', icon: 'mdi-account mdi-18px' },
          { title: 'Promotion', href: '/dashboard/promotions', icon: 'mdi-tag mdi-18px' },
          { title: 'Internal Stock Transfer', href: '/dashboard/stocks', icon: 'mdi-database mdi-18px' },
          { title: 'Orders', href: '/dashboard/orders', icon: 'mdi-file-document mdi-18px' },
          { title: 'Samples', href: '/dashboard/samples', icon: 'mdi-file-multiple mdi-18px' },
          {title: "Testers",href: "/dashboard/tester",icon: "mdi-file-multiple mdi-18px",},
          { title: 'Return', href: '/dashboard/returns', icon: 'mdi-truck mdi-18px' }, 
          { title: 'Stock Tracker', href: '/dashboard/stock_tracker', icon: 'mdi-inbox mdi-18px' },
          { title: 'Consigment Tracker', href: '/dashboard/consignment_tracker', icon: 'mdi-inbox mdi-18px' }, 
          { title: 'Influencer Tracker', href: '/dashboard/influencer_tracker', icon: 'mdi-account-multiple mdi-18px' },
          {
          title: "Retail Partner",
          href: "/dashboard/retail-partner",
          icon: "mdi-earth mdi-18px",
        },
          {
          title: "Faq",
          href: "/dashboard/faq",
          icon: "mdi-message-processing mdi-18px",
        },
        {
          title: "Faq Category",
          href: "/dashboard/faq_category",
          icon: "mdi-message-processing mdi-18px",
        },
        {
          title: "Recipe",
          href: "/dashboard/recipe",
          icon: "mdi-trello mdi-18px",
        },
        {
          title: "Newsletter Subscriber",
          href: "/dashboard/newsletter_subscriber",
          icon: "mdi-account mdi-18px",
        },
          // { title: 'Bill Item / Expense', href: '/dashboard/bill_item', icon: 'mdi-note-text mdi-18px' }, 
          // { title: 'Bill Payments', href: '/dashboard/bill_payments', icon: 'mdi-note-text mdi-18px' }, 
          // { title: 'Checks', href: '/dashboard/checks', icon: 'mdi-note-text mdi-18px' }, 
          // { title: 'Deposit', href: '/dashboard/deposit', icon: 'mdi-currency-usd mdi-18px' }, 
          // { title: 'Invoice Payment', href: '/dashboard/invoice_payment', icon: 'mdi-currency-usd mdi-18px' }, 
          // { title: 'Chart of Account', href: '/dashboard/chart_account', icon: 'mdi-chart-line mdi-18px' }, 
        ],
      }
    },
    methods: {
      toggleMini () {
        this.mini = !this.mini
      }
    }
}
</script>

<style>

  .navbar-list {
    overflow-y: auto;
  }
  .navbar-list::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .navbar-list {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
</style>