<template>
    <v-container fluid class="admin-page">
        <v-row align="center" justify="space-around">
            <v-col cols="6">
                <h2>Testers</h2>
            </v-col>
            <v-col cols="6">
            </v-col>
        </v-row>
        <v-row class="justify-space-start">
            <v-col cols="3">
                <div class="pa-3 crystal-admin-form">
                    <label>Status</label>
                    <v-select label="Choose Status" :items="tester_statuses" v-model="filterStatus" clearable>
                    </v-select>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <Alert />
            <v-col cols="12">
                <v-data-table :headers="headers" :items="testers" :server-items-length="totalTesters" :options="pagination"
                    @update:options="paginate" :footer-props="{
                        'items-per-page-options': listSize
                    }" @update:items-per-page="getItemPerPage" class="elevation-1">
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip>
                            {{ item.status }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                        <td>{{ formatDate(item.createdAt) }}</td>
                    </template>
                    <template v-slot:[`item.customer`]="{ item }">
                        <td v-if="item.Customer">
                            {{ item.Customer.fullName }}
                        </td>
                        <td v-else-if="item.Vendor">
                            {{ item.Vendor.company_name }}
                        </td>
                    </template>
                    <template v-slot:[`item.ship_from`]="{ item }">
                        {{ item.ShipFromLocation.LocationName }}
                    </template>
                    <template v-slot:[`item.ship_from`]="{ item }">
                        {{ item.ShipFromLocation.LocationName }}
                    </template>
                    <template v-slot:[`item.contact`]="{ item }">
                        <td v-if="item.Customer">
                            {{ item.Customer.CustomerContact.contactNo }}
                        </td>
                        <td v-if="item.Vendor">
                            {{ item.Vendor.VendorContact.phone_number }}
                        </td>
                    </template>
                    <template v-slot:[`item.ship_by`]="{ item }">
                        {{ item.DeliveryMethod.CourierCompany }}
                    </template>
                    <template v-slot:[`item.purpose`]="{ item }">
                        {{ capitalizeFirstLetter(item.purpose) }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        {{ capitalizeFirstLetter(item.status) }}
                    </template>
                    <template v-slot:[`item.products`]="{ item }">
                        <span v-for="(product, index) in item.TesterLine.items.slice(0, 3)" :key="index">
                            {{ product ? (product.Item ? product.Item.name : '') : "" }}{{ ` (x${product ? product.qty :
                                ""})` }}{{ index === 2 ? `...${item.TesterLine.items.length - 3} more` : index ===
        item.TesterLine.items.length - 1 ? '' : ',' }}
                        </span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div @click="getDetailOrder(item)">
                            <v-icon small text>mdi-eye</v-icon>

                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <DetailTester :order="selectedOrder" :detail_order="detailOrder" :detail_type="detail_type"
            @updateTester="updateTester" @closeDetail="closeDetail" />
    </v-container>
</template>

<script>
import DetailTester from '@/components/DetailTester.vue'
import Alert from '@/components/Alert.vue'
import dayjs from 'dayjs'
import server from '../api'

export default {
    name: 'Tester',
    components: {
        DetailTester,
        Alert
    },
    data() {
        return {
            detailOrder: false,
            detail_type: {
                text: 'Tester',
                value: 'tester'
            },
            selectedOrder: {},
            filterStatus: this.$route.query.status || '',
            menu2: false,
            headers: [
                { text: 'Date', value: 'date' },
                { text: 'Recipient', value: 'Customer.fullName' },
                { text: 'Product', value: 'products' },
                { text: 'Contact', value: 'contact' },
                { text: 'Ship From', value: 'ship_from' },
                { text: 'Ship By', value: 'ship_by' },
                { text: 'Purpose', value: 'purpose' },
                { text: 'Status', value: 'status' },
                { text: 'Action', value: 'actions' }
            ],
            listSize: [15, 25, 50, 100, -1],
            pagination: {
                descending: !!this.$route.query.desc,
                itemsPerPage: +this.$route.query.per_page || 15,
                page: +this.$route.query.page || 1,
                totalItems: this.totalOrders,
            },
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to);
        next();
    },
    watch: {
        filterStatus(val) {
            console.log(val)
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.status = val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
    },
    methods: {
        getDetailOrder(item) {
            this.selectedOrder = item; setInterval;
            this.detailOrder = true;
        },
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY');
        },
        closeDetail() {
            this.detailOrder = !this.detailOrder;
        },
        fetchData(route) {
            this.$store.dispatch('getTesters', route.query);
        },
        updateTester(id) {
            console.log({
                ShipFromLocationId: this.selectedOrder.ShipFromLocationId,
                DeliveryMethodId: this.selectedOrder.DeliveryMethodId,
                ShiptoAddress: this.selectedOrder.ShiptoAddress1,
                OrderStatus: this.selectedOrder.status,
                status: this.selectedOrder.status,
                tracking_code: this.selectedOrder.tracking_code,
                items: this.selectedOrder.TesterLine.items
            })
            server.put(`/tester/${id}`, {
                ShipFromLocationId: this.selectedOrder.ShipFromLocationId,
                DeliveryMethodId: this.selectedOrder.DeliveryMethodId,
                ShiptoAddress: this.selectedOrder.ShiptoAddress1,
                OrderStatus: this.selectedOrder.status,
                status: this.selectedOrder.status,
                tracking_code: this.selectedOrder.tracking_code,
                items: this.selectedOrder.TesterLine.items
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.commit('SET_SUCCESS', result.data.message);
                    this.detailOrder = !this.detailOrder;
                })
                .catch(error => {
                    this.$store.commit('SET_ERROR', error.response.data);
                });
        },
        getItemPerPage(val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            this.pagination.itemsPerPage = +val;
            obj.per_page = +val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        paginate(val) {
            // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
            if (val.descending) obj.desc = 'true';
            else delete obj.desc;
            obj.orderby = val.sortBy;
            obj.page = val.page;
            obj.per_page = val.itemsPerPage;
            // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
            let same = true;
            for (let key in query) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            // to handle the case when a KEY exists in OBJ but not in query
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
        },
    },
    computed: {
        testers() {
            return this.$store.state.testers;
        },
        products() {
            return this.$store.state.products;
        },
        totalTesters() {
            console.log(this.$store.state.totalTesters)
            return this.$store.state.totalTesters;
        },
        tester_statuses() {
            return this.$store.state.tester_statuses;
        },
    },
    created() {
        this.$store.dispatch('getTesters', this.$route.query);
        this.$store.dispatch('getProducts');
        this.$store.dispatch('getWarehouses');
    }
}
</script>

<style></style>