<template>
  <OrderForm 
    :order="order"
    @saveOrder="saveOrder"
    :buttonLoading="buttonLoading"
    :params="getParams"
  />
</template>

<script>
import OrderForm from '@/components/OrderForm.vue'
import server from '../api';

export default {
    name: 'AddOrder',
    components: {
        OrderForm
    },
    data () {
        return {
            order: {
                postal_code: '',
                ShiptoAddress: '',
                ShipStatus: '',
                OrderStatus: '',
                ShipFromLocationId: '',
                order_date: new Date().toISOString().substr(0, 10),
                shipment_date: new Date().toISOString().substr(0, 10),
                sales_type: '',
                customer_type: '',
                Customer: {
                    fullName: '',
                    CustomerContact: {
                        contactNo: '',
                        unit: '',
                        state: '',
                        country: '',
                    }
                },
                OrderLine: {
                    items: []
                },
                tax: 0,
                delivery_fee: 0,
                memo: '',
                invoice_no: '',
                shippingFlow: "manual",
                DeliveryMethodId: null,
                receiptNumber: ""
            },
            buttonLoading: false,
            getParams: null,
        }
    },
    befoureRouteEnter(to, from, next) {
        next(vm => {
            vm.getParams = from;
        });
    },
    methods: {
        saveOrder ({ grand_total, dial_code }) {
            this.buttonLoading = !this.buttonLoading;
            let firstName;
            let middleName;
            let lastName;
            if(this.order.Customer.fullName) {
                let name = this.order.Customer.fullName.split(' ');
                firstName = name[0] ? name[0] : '';
                middleName = name[1] ? name[1] : '';
                lastName = name[2] ? name[2] : '';
            }
            server.post('/customers', {
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                postal_code: this.order.postal_code,
                contactNo: `${dial_code + this.order.Customer.CustomerContact.contactNo}`,
                addressLine1: this.order.ShiptoAddress,
                unit: this.order.unit,
                state: this.order.state != "" ? this.order.state : "-",
                country: this.order.country,
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(results => {
                    return server.post('/orders', {
                        CustomerId: results.data.Customer.id,
                        ShipFromLocationId: this.order.ShipFromLocationId,
                        OrderStatus: this.order.OrderStatus,
                        ShipStatus: this.order.ShipStatus,
                        ShiptoAddress: this.order.ShiptoAddress,
                        country: this.order.country,
                        state: this.order.state,
                        unit: this.order.unit,
                        postal_code: this.order.postal_code,
                        delivery_fee: this.order.delivery_fee,
                        items: this.order.OrderLine.items,
                        sales_type: this.order.sales_type,
                        customer_type: this.order.customer_type,
                        order_date: this.order.order_date,
                        tax: this.order.tax,
                        grand_total,
                        memo: this.order.memo,
                        invoice_no: this.order.invoice_no,
                        shippingFlow: this.order.shippingFlow,
                        receiptNumber: this.order.receiptNumber,
                        DeliveryMethodId: this.order.DeliveryMethodId ?? 1,
                    }, {
                        headers: {
                            token: localStorage.token
                        }
                    })
                })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `berhasil menambahkan Order ${result.data.Order.id}`);
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', err.response.data.errors.message);
                });
        },
    },
}
</script>

<style>
</style>