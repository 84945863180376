<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="6"
        >
            <h2>Vendor List</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Vendor Name, Id"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                        v-model="filterVendor"
                        @keydown.enter="searchVendor"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/vendor">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add Vendor
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around crystal-admin-form">
        <v-col cols="12">
            <label>Company Name</label>
            <v-text-field
                placeholder="Search Company Name"
                clearable
                class="shrink"
                v-model="filterCompany"
                @keydown.enter="searchCompany"
            />
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <Alert />
            <v-data-table
                :headers="headers"
                :items="vendors"
                :server-items-length="totalVendors"
                :options="pagination"
                @update:options="paginate"
                :footer-props="{
                    'items-per-page-options': listSize
                }"
                @update:items-per-page="getItemPerPage"
                class="elevation-1"
            >
                <template v-slot:[`item.balance`]="{ item }">
                    <td>{{ item.balance ? formatHarga(item.balance) : formatHarga(0) }}</td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DeleteDialog 
        :message="`Remove Item ${vendor.company_name} ?`"
        :item="vendor"
        :showDelete="showDelete"
        @deleteItem="deleteVendor"
        @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import Alert from '../components/Alert.vue'
import DeleteDialog from '../components/DeleteDialog.vue'
import server from '../api'
import dayjs from 'dayjs'

export default {
    name: 'Vendor',
    components: {
        DeleteDialog,
        Alert
    },
    data () {
        return {
            headers: [
                { text: 'Name', value: 'fullName'},
                { text: 'Company Name', value: 'company_name'},
                { text: 'Email', value: 'VendorContact.email'},
                { text: 'Phone Number', value: 'VendorContact.phone_number'},
                { text: 'Balance', value: 'balance'},
                { text: 'Action', value: 'actions' }
            ],
            showDelete: false,
            vendor: {},
            filterVendor: '',
            filterCompany: '',
            listSize: [15, 25, 50, 100, -1],
            pagination: {
                descending: !!this.$route.query.desc,
                itemsPerPage: +this.$route.query.per_page || 15,
                page: +this.$route.query.page || 1,
                totalItems: this.totalOrders,
            },
        }
    },
    computed: {
        vendors () {
            return this.$store.state.vendors;
        },
        totalVendors () {
            return this.$store.state.totalVendors;
        }
    },
    watch: {
        filterVendor (val) {
            if (!val) {
                this.searchVendor();
            }
        },
        filterCompany (val) {
            if (!val) {
                this.searchCompany();
            }
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to);
        next();
    },
    methods: {
        getItemPerPage(val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            this.pagination.itemsPerPage = +val;
            obj.per_page = +val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        searchVendor () {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.vendor = this.filterVendor;
            if (this.filterVendor === '') delete obj.vendor;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        searchCompany () {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.company_name = this.filterCompany;
            if (this.filterCompany === '') delete obj.company_name;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        fetchData (route) {
            this.$store.dispatch('getVendors', route.query);
        },
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY')
        },
        showDeleteDialog (item) {
            this.showDelete = true
            this.vendor = item
        },
        deleteVendor (id) {
            this.showDelete = false
            server.delete(`/vendors/${id}`, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.dispatch('getVendors')
                    this.$store.commit('SET_SUCCESS', `Berhasil menghapus Vendors ${result.data.Vendor.company_name}`)
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message)
                });
        },
        closeDialog () {
            this.showDelete = false
        },
        editItem(item) {
            this.vendor = item
            this.$router.push({ path: `/dashboard/edit/vendor/${this.vendor.id}` })
        },
        paginate (val) {
            // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
            if (val.descending) obj.desc = 'true';
            else delete obj.desc;
            obj.orderby = val.sortBy;
            obj.page = val.page;
            obj.per_page = val.itemsPerPage;
            // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
            let same = true;
            for (let key in query) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            // to handle the case when a KEY exists in OBJ but not in query
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
        },
    },
    created () {
        this.$store.dispatch('getVendors', this.$route.query);
    }
}
</script>

<style>

</style>