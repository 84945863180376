import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import dayjs from 'dayjs';
import {countries} from "./components/country/country.json"

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
    formatHarga (number) {
        if(!number) number = 0;
        return 'SGD ' + number.toFixed(2);
    },
    removeCountryCode (country_code, phone_number) {
      let parsedNumber
      countries.map(country => {
        if (country_code == country.country_code) {
          parsedNumber = phone_number.replace(country.dial_code, '');
        }
      })

      return parsedNumber
    },
    getDialCode (country_code) {
      let dialCode
      countries.map(country => {
        if (country_code == country.country_code) {
          dialCode = country.dial_code;
        }
      })

      return dialCode
    },
    formatDate (date, format = 'DD/MM/YYYY') {
      return dayjs(date).format(format);
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
