<template>
  <ProductForm
    :item="product"
    :type="type"
    @saveProduct="saveProduct"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import ProductForm from "@/components/ProductForm.vue";
import moment from "moment";
import server from "../api";

export default {
  name: "EditProduct",
  components: {
    ProductForm,
  },
  data() {
    return {
      type: {
        text: "Edit",
        value: "edit",
      },
      buttonLoading: false,
    };
  },
  computed: {
    product() {
      let product = this.$store.state.product
      product.photos = []
      product.faqs = product.ItemFaqs
      
      return product;
    },
  },
  methods: {
    saveProduct(items) {
      this.buttonLoading = !this.buttonLoading;
      let data = new FormData();
      data.append("status", this.product.status);
      data.append("name", this.product.name);
      data.append("ecommerce_name", this.product.ecommerce_name);
      data.append("whatsapp_name", this.product.whatsapp_name);
      data.append("description", this.product.description);
      data.append("gross_weight", this.product.gross_weight);
      data.append("net_weight", this.product.net_weight);
      data.append("retail_price", this.product.retail_price);
      data.append("resaller_price", this.product.resaller_price);
      data.append("wholesaler_price", this.product.wholesaler_price);
      data.append("cost_price", this.product.cost_price);
      data.append("bundle_items", JSON.stringify(items));
      data.append("sku", this.product.sku);
      data.append("bpom_md", this.product.bpom_md);
      data.append("barcode", this.product.barcode);
      data.append("category_id", this.product.category_id);
      data.append("slug", this.product.slug);
      data.append("meta_title", this.product.meta_title);
      data.append("meta_keywords", this.product.meta_keywords);
      data.append("meta_description", this.product.meta_description);
      data.append("content_box", this.product.content_box);
      data.append("image_alt", this.product.image_alt);
      data.append("image_name", this.product.image_name);
      data.append("gross_price", typeof this.product.gross_price != "number" ? parseInt(this.product.gross_price) : this.product.gross_price);
      data.append("discount_product", typeof this.product.discount_product != "number" ? parseFloat(this.product.discount_product) : this.product.discount_product);
      data.append("tag_script", this.product.tag_script);
      this.product.photos.forEach((element, i) => data.append(`image${i}`, element));
      this.product.faqs.filter(faq => faq.locale == "en_US").forEach(element => data.append("faqs", JSON.stringify(element)));
      data.append("video3", this.product.video);
      data.append(
        "replace_image_name",
        this.product.replace_image_name ? true : false
      );
      data.append(
        "schedule_time",
        this.product.schedule_time
          ? moment(this.product.schedule_time).toISOString()
          : ""
      );

      server
        .put(`/products/${this.product.id}`, data, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((res) => {
          this.buttonLoading = !this.buttonLoading;
          this.$store.commit(
            "SET_SUCCESS",
            `Berhasil Memperbarui Product ${res.data.Product.name}`
          );
          this.$router.push({ name: "Inventory" });
        })
        .catch((err) => {
          this.buttonLoading = !this.buttonLoading;
          if (err.response.data.errors.length) {
            this.$store.commit(
              "SET_ERROR",
              err.response.data.errors[0].message
            );
          } else {
            this.$store.commit("SET_ERROR", err.response.data.errors.message);
          }
        });
    },
  },
  created() {
    this.$store.dispatch("getProductById", { id: this.$route.params.id });
  },
};
</script>

<style></style>
