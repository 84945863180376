<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>Add New Stock</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveStock()" :loading="buttonLoading" :disabled="buttonLoading">
                Save
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <v-container fluid class="crystal-admin-form">
        <v-row class="justify-space-around">
                <v-col cols="5">
                    <label>Type: </label>
                    <v-select
                        solo
                        placeholder="Type"
                        :items="types"
                        v-model="type"
                    ></v-select>
                    <label>From: </label>
                    <v-combobox
                        solo
                        placeholder="Enter Vendor/unit name"
                        :items="select_vendors"
                        item-text="company_name"
                        item-value="id"
                        v-model="VendorId"
                        v-if="type == 'purchases' || type == ''"
                    ></v-combobox>
                    <v-autocomplete
                        solo
                        :items="warehouses"
                        placeholder="From Warehouse"
                        item-text="LocationName"
                        item-value="id"
                        v-model="ShipFromLocationId"
                        v-if="type == 'inter_warehouse' || type == 'purchase_return' || type == 'adjustment'"
                    ></v-autocomplete>
                    <v-row>
                        <v-col
                        cols="5"
                        sm="6"
                        md="4"
                        > 
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="from_date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="from_date"
                                label="Shipping Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                append-icon="mdi-calendar"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="from_date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(from_date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                        <v-col
                        cols="6"
                        sm="6"
                        md="4"
                        >
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="to_date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="to_date"
                                label="Received Date"
                                readonly
                                v-bind="attrs"
                                append-icon="mdi-calendar"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="to_date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(to_date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="5">
                    <label v-if="type !== 'adjustment'">Destination: </label>
                    <v-autocomplete
                        solo
                        :items="warehouses"
                        placeholder="Destination"
                        item-text="LocationName"
                        item-value="id"
                        v-model="ShipToLocationId"
                        v-if="type == 'purchases' || type == '' || type == 'inter_warehouse'"
                    ></v-autocomplete>
                    <v-autocomplete
                        solo
                        placeholder="Enter Vendor/unit name"
                        :items="select_vendors"
                        item-text="company_name"
                        item-value="id"
                        v-model="ToVendorId"
                        v-if="type == 'purchase_return'"
                    ></v-autocomplete>
                    <label>Memo</label>
                    <v-textarea
                        solo
                        placeholder="Memo"
                        v-model="memo"
                    >    
                    </v-textarea>
                </v-col>
        </v-row>
        <hr>
        <v-container>
            <ProductOrder
                v-for="(product, index) in items"
                :key="product.id"
                :index="index"
                :product="product"
                @deleteProduct="deleteProduct"
                @updateProduct="updateProduct"
                type="stock"
            />
        </v-container>
        <v-row class="justify-center">
            <v-col cols="2">
                <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="addProduct()">
                    + Add Product
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <v-row class="justify-end">
            <v-col cols="2">
                <span>Total Quantity : {{ quantity }}</span>
            </v-col>
        </v-row>
      </v-container>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveStock()" :loading="buttonLoading" :disabled="buttonLoading">
                Save
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import ProductOrder from '../components/ProductOrder.vue'
import server from '../api'

export default {
    name: 'AddStock',
    components: {
        ProductOrder
    },
    data () {
        return {
            productStatus: 1,
            from_date: new Date().toISOString().substr(0, 10),
            to_date: new Date().toISOString().substr(0,10),
            menu: false,
            menu2: false,
            VendorId: null,
            ToVendorId: null,
            items: [],
            ShipFromLocationId: null,
            ShipToLocationId: null,
            type: 'purchases',
            memo: '',
            buttonLoading: false,
            types: [
                {
                    text: 'Purchases',
                    value: 'purchases'
                },
                {
                    text: 'Inter-Warehouse',
                    value: 'inter_warehouse'
                },
                {
                    text: 'Adjustment',
                    value: 'adjustment'
                },
                {
                    text: 'Puchase Return',
                    value: 'purchase_return'
                }
            ]
        }
    },
    methods: {
        saveStock () {
            this.buttonLoading = !this.buttonLoading;
            server.post('/internal_transfer', {
                from_date: this.from_date,
                to_date: this.to_date,
                vendor: this.VendorId,
                ToVendorId: this.ToVendorId,
                items: JSON.stringify(this.items),
                ShipFromLocationId: this.ShipFromLocationId,
                ShipToLocationId: this.ShipToLocationId,
                type: this.type,
                memo: this.memo,
                status: this.productStatus
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Menambahkan Internal Transfer Baru #${result.data.InternalTransfer.id}`)
                    this.$router.push({ name: 'InternalTransfer' });
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', `[ERROR] ${err.response.errors}`)
                    this.$router.push({ name: 'InternalTransfer' });
                })
        },
        addProduct () {
            let newProduct = {
                ItemId: '',
                qty: '',
                Item: {
                    name: ''
                }
            }
            this.items.push(newProduct)
        },
        deleteProduct(index) {
            this.items.splice(index, 1)
        },
        updateProduct (index, id, qty, price) {
            this.items[index].ItemId = id
            this.items[index].qty = qty
            this.items[index].price = price
        }
    },
    computed: {
        select_vendors () {
            return this.$store.state.select_vendors
        },
        warehouses () {
            return this.$store.state.warehouses
        },
        quantity () {
            let quantity = 0;
            for(let i = 0; i < this.items.length; i++) {
                quantity += +this.items[i].qty
            }
            return quantity
        }
    },
    watch: {
        ShipFromLocationId () {
            this.VendorId = null;
        },
        type () {
            this.VendorId = null;
            this.ToVendorId = null;
            this.ShipFromLocationId = null;
            this.ShipToLocationId = null;
        }
    },
    created () {
        this.$store.dispatch('getVendorsLite')
        this.$store.dispatch('getWarehouses')
    }
}
</script>

<style>
</style>