import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Inventory from '../views/Inventory.vue'
import AddProduct from '../views/AddProduct.vue'
import Delivery from '../views/Delivery.vue'
import AddCourier from '../views/AddCourier.vue'
import Vendor from '../views/Vendor.vue'
import AddVendor from '../views/AddVendor.vue'
import Customer from '../views/Customer.vue'
import AddCustomer from '../views/AddCustomer.vue'
import Promotion from '../views/Promotion.vue'
import AddPromotion from '../views/AddPromotion.vue'
import Stock from '../views/Stock.vue'
import AddStock from '../views/AddStock.vue'
import Order from '../views/Order.vue'
import AddOrder from '../views/AddOrder.vue'
import Sample from '../views/Sample.vue'
import AddSample from '../views/AddSample.vue'
import Return from '../views/Return.vue'
import StockTracker from '../views/StockTracker.vue'
import AddDataEntry from '../views/AddDataEntry.vue'
import ConsignmentTracker from '../views/ConsignmentTracker.vue'
import InfluencerTracker from '../views/InfluencerTracker.vue'
import Expense from '../views/Expense.vue'
import AddInternalStock from '../views/AddInternalStock.vue'
import BillPayment from '../views/BillPayment.vue'
import Checks from  '../views/Checks.vue'
import AddCheck from '../views/AddCheck.vue'
import Deposit from '../views/Deposit.vue'
import InvoicePayment from '../views/InvoicePayment.vue'
import ChartAccount from '../views/ChartAccount.vue'
import AddNewAccount from '../views/AddNewAccount.vue'
import ShipFrom from '../views/ShipFrom.vue'
import AddWarehouse from '../views/AddWarehouse.vue'
import EditProduct from '../views/EditProduct.vue'
import EditCourier from '../views/EditCourier.vue'
import EditWarehouse from '../views/EditWarehouse.vue'
import EditCustomer from '../views/EditCustomer.vue'
import EditVendor from '../views/EditVendor.vue'
import EditPromotion from '../views/EditPromotion.vue'
import EditOrder from '../views/EditOrder.vue'
import EditSample from '../views/EditSample.vue'
import ConsignmentInvoice from "../views/ConsignmentInvoice.vue";
import AddInvoice from "../views/AddInvoice.vue";
import EditInvoice from "../views/EditInvoice.vue";
import RetailPartner from "../views/RetailPartner";
import AddRetailPartner from "../views/AddRetailPartner";
import EditRetailPartner from "../views/EditRetailPartner";
import Faq from "../views/Faq";
import AddFaq from "../views/AddFaq";
import EditFaq from "../views/EditFaq";
import FaqCategory from "../views/FaqCategory";
import AddFaqCategory from "../views/AddFaqCategory";
import EditFaqCategory from "../views/EditFaqCategory";
import Tester from "../views/Tester.vue";
import Recipe from "../views/Recipe";
import AddRecipe from "../views/AddRecipe";
import EditRecipe from "../views/EditRecipe";
import NewsletterSubsriber from "../views/NewsletterSubscriber";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: 'inventory',
        name: 'Inventory',
        component: Inventory
      },
      {
        path: 'add/product',
        component: AddProduct,
        name: 'AddProduct'
      },
      {
        path: 'edit/product/:id',
        component: EditProduct,
        name: 'EditProduct'
      },
      {
        path: 'edit/courier/:id',
        component: EditCourier,
        name: 'EditCourier'
      },
      {
        path: 'add/courier',
        name: 'AddCourier',
        component: AddCourier
      },
      {
        path: 'add/vendor',
        name: 'AddVendor',
        component: AddVendor
      },
      {
        path: 'delivery',
        name: 'Delivery',
        component: Delivery
      },
      {
        path: 'vendors',
        name: 'Vendor',
        component: Vendor
      },
      {
        path: 'edit/vendor/:id',
        name: 'EditVendor',
        component: EditVendor
      },
      {
        path: 'customers',
        name: 'Customer',
        component: Customer
      },
      {
        path: 'edit/customer/:id',
        name: 'EditCustomer',
        component: EditCustomer
      },
      {
        path: 'add/customer',
        component: AddCustomer
      },
      {
        path: 'promotions',
        name: 'Promotion',
        component: Promotion
      },
      {
        path: 'add/promotion',
        component: AddPromotion
      },
      {
        path: 'edit/promotion/:id',
        name: 'EditPromotion',
        component: EditPromotion
      },
      {
        path: 'stocks',
        name: 'InternalTransfer',
        component: Stock
      },
      {
        path: 'add/stock',
        component: AddStock
      },
      {
        path: 'orders',
        name: 'Order',
        component: Order
      },
      {
        path: 'add/order',
        component: AddOrder
      },
      {
        path: 'edit/order/:id',
        name: 'EditOrder',
        component: EditOrder
      },
      {
        path: 'samples',
        name: 'Sample',
        component: Sample
      },
      {
        path: "tester",
        name: "Tester",
        component: Tester,
      },
      {
        path: 'add/sample',
        component: AddSample
      },
      {
        path: 'edit/sample/:id',
        component: EditSample
      },
      {
        path: 'returns',
        name: 'Return',
        component: Return
      },
      {
        path: 'add/return',
        component: AddDataEntry
      },
      {
        path: 'add/return/:id',
        component: AddDataEntry
      },
      {
        path: 'stock_tracker',
        component: StockTracker
      },
      {
        path: 'consignment_tracker',
        component: ConsignmentTracker
      },
      {
        path: 'influencer_tracker',
        component: InfluencerTracker
      },
      {
        path: 'bill_item',
        component: Expense
      },
      {
        path: 'addinternalstock',
        component: AddInternalStock
      },
      {
        path: 'bill_payments',
        component: BillPayment
      },
      {
        path: 'checks',
        component: Checks
      },
      {
        path: 'addcheck',
        component: AddCheck
      },
      {
        path: 'deposit',
        component: Deposit
      },
      {
        path: 'invoice_payment',
        component: InvoicePayment
      },
      {
        path: 'chart_account',
        component: ChartAccount
      },
      {
        path: 'addaccount',
        component: AddNewAccount
      },
      {
        path: 'warehouse',
        name: 'Warehouse',
        component: ShipFrom
      },
      {
        path: 'edit/warehouse/:id',
        name: 'EditWarehouse',
        component: EditWarehouse
      },
      {
        path: 'add/warehouse',
        name: 'AddWarehouse',
        component: AddWarehouse
      },
      {
        path: '',
        name: 'Dashboard',
        component: Inventory
      },
      {
        path: "consignment_invoice",
        component: ConsignmentInvoice,
      },
      {
        path: "add/invoice",
        name: "AddInvoice",
        component: AddInvoice,
      },
      {
        path: "edit/invoice/:id",
        name: "EditInvoice",
        component: EditInvoice,
      },
      {
        path: "retail-partner",
        name: "RetailPartner",
        component: RetailPartner,
      },
      {
        path: "add/retail-partner",
        name: "AddRetailPartner",
        component: AddRetailPartner,
      },
      {
        path: "edit/retail-partner/:id",
        name: "EditRetailPartner",
        component: EditRetailPartner,
      },
      {
        path: "faq",
        name: "Faq",
        component: Faq,
      },
      {
        path: "add/faq",
        name: "AddFaq",
        component: AddFaq,
      },
      {
        path: "edit/faq/:id",
        name: "EditFaq",
        component: EditFaq,
      },
      {
        path: "faq_category",
        name: "FaqCategory",
        component: FaqCategory,
      },
      {
        path: "add/faq_category",
        name: "AddFaqCategory",
        component: AddFaqCategory,
      },
      {
        path: "edit/faq_category/:id",
        name: "EditFaqCategory",
        component: EditFaqCategory,
      },
      {
        path: "recipe",
        name: "Recipe",
        component: Recipe,
      },
      {
        path: "add/recipe",
        name: "AddRecipe",
        component: AddRecipe,
      },
      {
        path: "edit/recipe/:slug",
        name: "EditRecipe",
        component: EditRecipe,
      },
      {
        path: "newsletter_subscriber",
        name: "NewsletterSubsriber",
        component: NewsletterSubsriber,
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
