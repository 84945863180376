<template>
    <v-container fluid class="admin-page">
      <v-row class="align-center" justify="space-around">
        <v-col
        cols="6"
        >
            <h2>Courier List</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Courier"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn
                        @click.prevent="reloadPage()"
                    >
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/courier">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add Courier
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around crystal-admin-form">
        <v-col cols="12" class="ml-10">
            <label class="text--futura">Price</label>
        </v-col>
        <v-col cols="5" class="crystal-input">
            <v-text-field
                dense
                placeholder="Min"       
                class="mt-5"
                v-model="minPrice"
            >
            </v-text-field>
        </v-col>
        <label>Or</label>
        <v-col cols="5">
            <v-text-field
                dense
                placeholder="Max"
                class="mt-5"
                v-model="maxPrice"
            >
            </v-text-field>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <Alert />
            <v-data-table
                :headers="headers"
                :items="courierList"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        v-model="item.status"
                    ></v-switch>
                </template>
                <template v-slot:[`item.unitFees`]="{ item }">
                    <td>{{ item.unitFees ? item.unitFees : 0 }} Kg.</td>
                </template>
                <template v-slot:[`item.fees`]="{ item }">
                    <td>{{ formatHarga(item.fees) }}</td>
                </template>
                <template v-slot:[`item.origins`]="{ item }">
                    <td>{{ filterCity(item.origins) }}</td>
                </template>
                <template v-slot:[`item.destination`]="{ item }">
                    <td>{{ filterCity(item.destination) }}</td>
                </template>
                <template v-slot:[`item.deliveryType`]="{ item }">
                    <td>{{ item.deliveryType ? 'International' : 'Domestic' }}</td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editCourier(item)">mdi-pencil</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DeleteDialog 
        :message="`Remove Delivery ${item.DeliveryMethod ? item.DeliveryMethod.CourierCompany : ''} - ${item.code} ?`"
        :item="item"
        :showDelete="showDelete"
        @deleteItem="deleteCourier"
        @closeDialog="closeDialog"
    />
    </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import server from '../api';

export default {
    name: 'Delivery',
    components: {
        Alert,
        DeleteDialog
    },
    data () {
        return {
             headers: [
                { text: 'Courier', value: 'DeliveryMethod.CourierCompany' },
                { text: 'Service Name', value: 'code' },
                { text: 'Fees', value: 'fees' },
                { text: 'Unit Fees', value: 'unitFees' },
                { text: 'Type', value: 'deliveryType' },
                { text: 'Origins', value: 'origins' },
                { text: 'Destination', value: 'destination' },
                { text: 'Status', value: 'status'},
                { text: 'Actions', value: 'actions'}
            ],
            minPrice: '',
            maxPrice: '',
            showDelete: false,
            item: {}
        }
    },
    computed: {
        courierList () {
            if(this.minPrice && this.maxPrice) {
                return this.$store.state.courierList.filter(courier => courier.fees >= this.minPrice && courier.fees <= this.maxPrice)
            } else if(this.maxPrice) {
                return this.$store.state.courierList.filter(courier => courier.fees <= this.maxPrice)
            } else if(this.minPrice) {
                return this.$store.state.courierList.filter(courier => (courier.fees >= this.minPrice))
            } else {
                return this.$store.state.courierList
            }
        },
    },
    methods: {
        filterCity(id) {
            let city = this.$store.state.cities.filter(city => city.city_id == id)
            return city[0] ? `${city[0].type} ${city[0].name}` : ''
        },
        reloadPage(){
            window.location.reload()
        },
        showDeleteDialog (item) {
            this.item = item;
            this.showDelete = true;
        },
        closeDialog () {
            this.showDelete = false;
        },
        deleteCourier () {
            this.showDelete = false;
            server.delete(`/delivery/fees/${this.item.id}`)
                .then(result => {
                    this.$store.dispatch('getCourierList');
                    this.$store.commit('SET_SUCCESS', `Berhasil menghapus Courier ${result.data.Delivery.DeliveryMethod.CourierCompany} - ${result.data.Delivery.code}`);
                })
                .catch(err => {
                    this.$store.commit('SET_ERRORS', err.response.data.errors.message);
                });
        },
        editCourier (item) {
            this.item = item;
            this.$router.push({ path: `/dashboard/edit/courier/${this.item.id}` });
        }
    },
    created () {
        this.$store.dispatch('getCourierList')
        this.$store.dispatch('getCities')
    }
}
</script>

<style>

</style>