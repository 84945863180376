<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center" justify="space-around">
        <v-col
        cols="6"
        >
            <h2>Promotion List</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Customer"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/promotion">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add Promotion
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around crystal-admin-form">
        <v-col cols="12">       
            <label>Valid Period</label>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="valid_period"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="valid_period"
                    label="To"
                    readonly
                    v-bind="attrs"
                    append-icon="mdi-calendar"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="valid_period"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(valid_period)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-menu>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <Alert />
            <v-data-table
                :headers="headers"
                :items="promotions"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:[`item.promoStatus`]="{ item }">
                    <v-switch
                        v-model="item.promoStatus"
                        ></v-switch>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        v-model="item.status"
                        ></v-switch>
                </template>
                <template v-slot:[`item.valid_period`]="{ item }">
                    <td>{{ formatDate(item.valid_from) }} - {{ formatDate(item.valid_until) }}</td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DeleteDialog 
        :message="`Remove Promotion ${promotion.promoName} ?`"
        :item="promotion"
        :showDelete="showDelete"
        @deleteItem="deletePromotion"
        @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import Alert from '../components/Alert.vue'
import DeleteDialog from '../components/DeleteDialog.vue'
import server from '../api'
import dayjs from 'dayjs'

export default {
    name: 'Promotion',
    components: {
        Alert,
        DeleteDialog
    },
    data () {
        return {
            headers: [
                { text: 'Name', value: 'promoName'},
                { text: 'Country Code', value: 'countryCode'},
                { text: 'Coupon Code', value: 'promoCode'},
                { text: 'Valid Period', value: 'valid_period'},
                { text: 'Quota', value: 'Quota'},
                { text: 'Status', value: 'promoStatus'},
                { text: 'Action', value: 'actions' }
            ],
            showDelete: false,
            promotion: {},
            valid_period: new Date().toISOString().substr(0,10),
            menu: false
        }
    },
    methods: {
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY')
        },
        showDeleteDialog (promotion) {
            this.promotion = promotion
            this.showDelete = true
        },
        closeDialog() {
            this.showDelete = false
        },
        deletePromotion (id) {
            server.delete(`/promotion/${id}`, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.showDelete = false
                    this.$store.dispatch('getPromotions')
                    this.$store.commit('SET_SUCCESS', `Berhasil Menghapus Promotion ${result.data.Promotion.promoName}`)
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.data.response.errors[0].message)
                })
        },
        editItem(item) {
            this.promotion = item
            this.$router.push({ path: `/dashboard/edit/promotion/${this.promotion.id}` })
        }
    },
    computed: {
        promotions () {
            return this.$store.state.promotions
        }
    },
    created () {
        this.$store.dispatch('getPromotions')
    }
}
</script>

<style>

</style>