<template>
  <VendorForm 
    :vendor="vendor"
    @saveVendor="saveVendor"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import VendorForm from '@/components/VendorForm.vue';
import server from '../api';

export default {
    name: 'AddVendor',
    components: {
        VendorForm
    },
    data () {
        return {
            vendor: {
                company_name: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                gender: 0,
                tax_statuses: [
                    'PKP',
                    'Non PKP'
                ],
                VendorContact: {
                    email: '',
                    phone_number: '',
                    address: '',
                    country: '',
                    state: '',
                    unit: '',
                    account_name: '',
                    account_number: '',
                    bank_branch: '',
                    bank_name: '',
                    postal_code: '',
                    tax_status: '',
                }
            },
            buttonLoading: false
        }
    },
    methods: {
        saveVendor (dial_code) {
            this.buttonLoading = !this.buttonLoading;
            server.post('/vendors', {
                company_name: this.vendor.company_name,
                first_name: this.vendor.first_name,
                middle_name: this.vendor.middle_name,
                last_name: this.vendor.last_name,
                email: this.vendor.VendorContact.email,
                phone_number: `${dial_code + this.vendor.VendorContact.phone_number}`,
                address: this.vendor.VendorContact.address,
                country: this.vendor.VendorContact.country,
                state: this.vendor.VendorContact.state,
                unit: this.vendor.VendorContact.unit,
                account_name: this.vendor.VendorContact.account_name,
                account_number: this.vendor.VendorContact.account_number,
                bank_branch: this.vendor.VendorContact.bank_branch,
                bank_name: this.vendor.VendorContact.bank_name,
                gender: this.vendor.gender,
                postal_code: this.vendor.VendorContact.postal_code,
                tax_status: this.vendor.VendorContact.tax_status,
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$router.push({ name: 'Vendor' });
                    this.$store.commit('SET_SUCCESS', `Berhasil menambahkan Vendor ${result.data.Vendor.company_name}`);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                });
        }
    },
}
</script>

<style>

</style>