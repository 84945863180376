var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Courier List")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Search Courier","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""}})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.reloadPage()}}},[_c('v-icon',[_vm._v("mdi-history")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/dashboard/add/courier"}},[_c('v-btn',{staticClass:"text--futura text--white text-capitalize",attrs:{"dark":"","block":""}},[_vm._v(" Add Courier ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"justify-space-around crystal-admin-form"},[_c('v-col',{staticClass:"ml-10",attrs:{"cols":"12"}},[_c('label',{staticClass:"text--futura"},[_vm._v("Price")])]),_c('v-col',{staticClass:"crystal-input",attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"mt-5",attrs:{"dense":"","placeholder":"Min"},model:{value:(_vm.minPrice),callback:function ($$v) {_vm.minPrice=$$v},expression:"minPrice"}})],1),_c('label',[_vm._v("Or")]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"mt-5",attrs:{"dense":"","placeholder":"Max"},model:{value:(_vm.maxPrice),callback:function ($$v) {_vm.maxPrice=$$v},expression:"maxPrice"}})],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('Alert'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.courierList,"items-per-page":5},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.unitFees",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.unitFees ? item.unitFees : 0)+" Kg.")])]}},{key:"item.fees",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatHarga(item.fees)))])]}},{key:"item.origins",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.filterCity(item.origins)))])]}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.filterCity(item.destination)))])]}},{key:"item.deliveryType",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.deliveryType ? 'International' : 'Domestic'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editCourier(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('DeleteDialog',{attrs:{"message":("Remove Delivery " + (_vm.item.DeliveryMethod ? _vm.item.DeliveryMethod.CourierCompany : '') + " - " + (_vm.item.code) + " ?"),"item":_vm.item,"showDelete":_vm.showDelete},on:{"deleteItem":_vm.deleteCourier,"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }