var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"8"}},[_c('h2',[_vm._v("Stock Tracker")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Search Item","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""},on:{"click:append":_vm.searchItem,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchItem($event)}},model:{value:(_vm.filterItem),callback:function ($$v) {_vm.filterItem=$$v},expression:"filterItem"}})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"2"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-history")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"justify-space-around"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pa-3 crystal-admin-form"},[_c('label',[_vm._v("Filter Date")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.filter_date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.filter_date=$event},"update:return-value":function($event){_vm.filter_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Stock Until","readonly":"","append-icon":"mdi-calendar"},model:{value:(_vm.filter_date),callback:function ($$v) {_vm.filter_date=$$v},expression:"filter_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filter_date),callback:function ($$v) {_vm.filter_date=$$v},expression:"filter_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.filter_date)}}},[_vm._v(" OK ")])],1)],1)],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pa-3 crystal-admin-form"},[_c('label',[_vm._v("Ship From")]),_c('v-select',{attrs:{"label":"Choose Warehouse","items":_vm.warehouses,"item-text":"LocationName","item-value":"id","clearable":""},model:{value:(_vm.filterWarehouse),callback:function ($$v) {_vm.filterWarehouse=$$v},expression:"filterWarehouse"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('Alert'),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.stockCount,"items-per-page":5,"footer-props":{
                  'items-per-page-options': _vm.listSize,
              },"loading-text":"Loading Stock Data...","sort-by":_vm.sortBy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.RealStock",fn:function(ref){
              var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{key:"item.id",attrs:{"label":"Real Stock","hide-details":""},model:{value:(item.RealStock),callback:function ($$v) {_vm.$set(item, "RealStock", $$v)},expression:"item.RealStock"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.buttonLoading,"disabled":_vm.buttonLoading},on:{"click":function($event){return _vm.updateStock(item)}}},[_vm._v("Save")])],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }