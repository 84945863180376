<template>
  <v-dialog v-model="showInvoice" width="60vw">
    <v-container style="backgroundColor:white;width:100%;">
      <v-row class="pa-2 py-1">
        <v-col cols="6">
          <h4>Detail Customer</h4>
          <v-row>
            <v-col cols="4" class="py-0">
              Name Customer
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.customer.fullName }}
            </v-col>
            <v-col cols="4" class="py-0">
              Email
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.customer.CustomerContact.email }}
            </v-col>
            <v-col cols="4" class="py-0">
              Company Name
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.customer.company_name }}
            </v-col>
            <v-col cols="4" class="py-0">
              Address
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.customer.CustomerContact.addressLine1 }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <h4>Detail Invoice</h4>
          <v-row>
            <v-col cols="4" class="py-0">
              Invoice Number
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.invoice_no }}
            </v-col>
            <v-col cols="4" class="py-0">
              Date Invoice
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ invoiceDate }}
            </v-col>
            <v-col cols="4" class="py-0">
              Order Number
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.order_id }}
            </v-col>
            <v-col cols="4" class="py-0">
              Order Total
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              SGD. {{ dataDetailInvoice.grand_total }}
            </v-col>
            <v-col cols="4" class="py-0">
              Type
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.type }}
            </v-col>
            <v-col cols="4" class="py-0">
              Memo
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ dataDetailInvoice.memo }}
            </v-col>
            <v-col cols="4" class="py-0">
              No. Rekening
            </v-col>
            <v-col cols="1" class="py-0">
              :
            </v-col>
            <v-col cols="7" class="py-0">
              {{ "123456780 (BCA)" }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="dataDetailInvoice.invoice_items"
            hide-default-footer
            class="elevation-5"
          ></v-data-table>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-row class="justify-space-between">
            <v-col cols="2"
              ><v-btn elevation="2" color="error" @click="closeModal"
                >Close</v-btn
              ></v-col
            >
            <v-col cols="2" align="end">
              <v-btn elevation="2" @click="printInvoice">
                Print
                <v-icon right dark>
                  mdi-printer
                </v-icon>
              </v-btn></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "InvoiceDetail",
  data() {
    return {
      headers: [
        { text: "Name Product", value: "Item.name" },
        { text: "Quantity", value: "qty" },
        { text: "Price", value: "price" },
      ],
    };
  },
  props: {
    showInvoice: {
      type: Boolean,
      default: false,
    },
    dataDetailInvoice: {
      type: Object,
      default: () => {
        return {
          customer: {
            CustomerContact: {},
          },
          invoice_items: [],
        };
      },
    },
  },
  computed: {
    invoiceDate() {
      return moment(this.dataDetailInvoice.invoice_date).format("YYYY-MM-DD");
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    printInvoice() {
      this.$emit("printInvoice");
    },
  },
};
</script>
