<template>
  <WarehouseForm 
    :warehouse="warehouse"
    @saveWarehouse="updateWarehouse"
    :buttonLoading="buttonLoading"
    :type="type"
  />
</template>

<script>
import WarehouseForm from '@/components/WarehouseForm';
import server from '../api';

export default {
    name: 'EditWarehouse',
    data () {
        return {
            buttonLoading: false,
            type: {
                text: 'Edit',
                value: 'edit'
            }
        }
    },
    components: {
        WarehouseForm
    },
    computed: {
        warehouse () {
            return this.$store.state.warehouse
        }
    },
    methods: {
        updateWarehouse (dial_code) {
            this.buttonLoading = !this.buttonLoading;
            if (!this.warehouse.phone_number.includes(dial_code)) {
                this.warehouse.phone_number = dial_code + this.warehouse.phone_number
            }
            server.put(`/warehouse/${this.warehouse.id}`, {
                LocationName: this.warehouse.LocationName,
                latitude: this.warehouse.latitude,
                longitude: this.warehouse.longitude,
                addressLine1: this.warehouse.addressLine1,
                phone_number: this.warehouse.phone_number,
                status: this.warehouse.status,
                postal_code: this.warehouse.postal_code,
                country: this.warehouse.country,
                state: this.warehouse.state,
                unit: this.warehouse.unit,
                pic: this.warehouse.pic
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Memperbarui Warehouse ${result.data.Warehouse.LocationName}`);
                    this.$router.push({ name: 'Warehouse' });
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    if(err.response.data.errors.length) {
                        this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                    } else {
                        this.$store.commit('SET_ERROR', err.response.data.errors.message);
                    }
                })
        }
    },
    created () {
        this.$store.dispatch('getWarehouseById', { id: this.$route.params.id })
    }
}
</script>

<style>

</style>