<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center" justify="space-around">
        <v-col
        cols="6"
        >
            <h2>Customer List</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Customer"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                        v-model="filterCustomer"
                        @keydown.enter="searchCustomer"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/customer">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add Customer
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around crystal-admin-form">
        <v-col cols="12">       
            <label>Customer Type</label>
            <v-select
                :items="customer_types"
                solo
                label="Choose Type"
                v-model="filterType"
            >
            </v-select>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <Alert />
            <v-data-table
                :headers="headers"
                :items="customers"
                :server-items-length="totalCustomers"
                :options="pagination"
                @update:options="paginate"
                :footer-props="{
                    'items-per-page-options': listSize
                }"
                @update:items-per-page="getItemPerPage"
                class="elevation-1"
            >
                <template v-slot:[`item.name`]="{ item }">
                    <td>{{ item.firstName ? item.firstName : '' }} {{ item.middleName ? item.middleName : '' }} {{ item.lastName ? item.lastName : '' }}</td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editCustomer(item)">mdi-pencil</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DeleteDialog 
        :message="`Remove Customer ${customer.firstName} ?`"
        :item="customer"
        :showDelete="showDelete"
        @deleteItem="deleteCustomer"
        @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import server from '../api'
import Alert from '../components/Alert.vue'
import DeleteDialog from '../components/DeleteDialog.vue'

export default {
    name: 'Customer',
    components: {
        Alert,
        DeleteDialog
    },
    data () {
        return {
            showDelete: false,
            customer: {},
            headers: [
                { text: 'Name', value: 'name'},
                { text: 'Email', value: 'CustomerContact.email'},
                { text: 'Phone Number', value: 'CustomerContact.contactNo'},
                { text: 'Action', value: 'actions' }
            ],
            filterCustomer: this.$route.query.customer || '',
            listSize: [15, 25, 50, 100, -1],
            pagination: {
                descending: !!this.$route.query.desc,
                itemsPerPage: +this.$route.query.per_page || 15,
                page: +this.$route.query.page || 1,
                totalItems: this.totalOrders,
            },
            filterType: ''
        }
    },
    computed: {
        customers () {
            return this.$store.state.customers;
        },
        totalCustomers () {
            return this.$store.state.totalCustomers;
        },
        customer_types () {
            return this.$store.state.customer_types;
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to);
        next();
    },
    watch: {
        filterCustomer (val) {
            if (!val) {
                this.searchCustomer();
            }
        },
        filterType (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.customer_type = val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
    },
    methods: {
        searchCustomer () {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.customer = this.filterCustomer;
            if (this.filterCustomer === '') delete obj.customer;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        showDeleteDialog(item) {
            this.customer = item;
            this.showDelete = !this.showDelete;
        },
        closeDialog() {
            this.showDelete = !this.showDelete;
        },
        deleteCustomer (id) {
            this.showDelete = !this.showDelete;
            server.delete(`/customers/${id}`)
                .then(result => {
                    this.$store.dispatch('getCustomers', this.$route.query);
                    this.$store.commit('SET_SUCCESS', `Berhasil menghapus Customer ${result.data.Customer.firstName}`);
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                });
        },
        editCustomer (item) {
            this.customer = item;
            this.$router.push({ path: `/dashboard/edit/customer/${this.customer.id}` });
        },
        fetchData (route) {
            this.$store.dispatch('getCustomers', route.query);
        },
        paginate (val) {
            // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
            if (val.descending) obj.desc = 'true';
            else delete obj.desc;
            obj.orderby = val.sortBy;
            obj.page = val.page;
            obj.per_page = val.itemsPerPage;
            // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
            let same = true;
            for (let key in query) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            // to handle the case when a KEY exists in OBJ but not in query
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
        },
        getItemPerPage(val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            this.pagination.itemsPerPage = +val;
            obj.per_page = +val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
    },
    created () {
        this.$store.dispatch('getCustomers', this.$route.query);
    }
}
</script>

<style>

</style>