<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="6"
        >
            <h2>Returns</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Return"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/return">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add New Return
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="3" class="crystal-admin-form">       
            <label>Date</label>
            <v-text-field
                placeholder="Choose Date"
            />
        </v-col>
        <v-col cols="3" class="crystal-admin-form">       
            <label>From- Destination</label>
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        placeholder="From"
                    />
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        placeholder="To"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="3" class="crystal-admin-form">       
            <label>Type</label>
            <v-text-field
                placeholder="Choose Date"
            />
        </v-col>
        <v-col cols="2" class="crystal-admin-form">
            <label>Stock</label>    
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        placeholder="min"
                    />
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        placeholder="max"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <Alert />
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="returns"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    >
                        {{item.status}}
                    </v-chip>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    {{ formatDate(item.createdAt) }}
                </template>
                <template v-slot:[`item.products`]="{ item }">
                    <span v-for="(product, index) in item.items.slice(0, 3)" :key="product.id">
                        {{ product.Item ? product.Item.name : '' }}{{` (x${product.qty})` }}{{ index === 2 && (item.items.length - 3) !== 0 ? `...${item.items.length - 3} more` : index === item.items.length-1 ? '' : ',' }}
                    </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small text class="mr-2" @click="getDetailOrder(item)">mdi-eye</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DetailReturn
        :order="selectedOrder"
        :detail_return="detail_return"
        @closeDetail="closeDetail"
    />
    <DeleteDialog 
        :message="`Remove Return ${selectedOrder.id} ?`"
        :item="selectedOrder"
        :showDelete="showDelete"
        @deleteItem="deleteReturn"
        @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import DetailReturn from '@/components/DetailReturn.vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import server from '../api';
import dayjs from 'dayjs'
import Alert from '@/components/Alert.vue'

export default {
    name: 'Return',
    components: {
        DetailReturn,
        DeleteDialog,
        Alert
    },
    data () {
        return {
            detail_return: false,
            showDelete: false,
            selectedOrder: {},
            headers: [
                { text: 'Date', value: 'date'},
                { text: 'Recipient', value: 'Order.Customer.fullName'},
                { text: 'Product Returned', value: 'products'},
                { text: 'Return to', value: 'ShipFromLocation.LocationName'},
                { text: 'Order Id', value: 'OrderId'},
                { text: 'Status', value: 'status'},
                { text: 'Action', value: 'actions'}
            ]
        }
    },
    methods: {
        getDetailOrder(item) {
            this.selectedOrder = item;
            this.detail_return = !this.detail_return;
        },
        formatDate (date) {
            return dayjs(date).format('DD/MM/YYYY');
        },
        closeDetail () {
            this.detail_return = !this.detail_return;
        },
        showDeleteDialog (item) {
            this.selectedOrder = item;
            this.showDelete = !this.showDelete;
        },
        closeDialog () {
            this.showDelete = !this.showDelete;
        },
        deleteReturn (id) {
            this.showDelete = !this.showDelete;
            server.delete(`/returns/${id}`)
                .then(result => {
                    this.$store.dispatch('getReturns');
                    this.$store.commit('SET_SUCCESS', `Berhasil menghapus Return ${result.data.Return.id}`);
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                });
        }
    },
    computed: {
        returns () {
            return this.$store.state.returns;
        }
    },
    created () {
        this.$store.dispatch('getReturns');
    }
}
</script>

<style>

</style>