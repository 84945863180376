<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>{{ type.text }} Warehouse</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveWarehouse()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ type.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <Alert />
      <v-row class="crystal-admin-form justify-space-around pt-5">
            <v-col cols="5">
                <label>Warehouse name: </label>
                <v-text-field
                    solo
                    placeholder="Warehouse Name"
                    v-model="warehouse.LocationName"
                ></v-text-field>
                <v-row>
                    <v-col cols="6">
                        <label>Latitude</label>
                        <v-text-field
                            solo
                            placeholder="Enter latitude"
                            v-model="warehouse.latitude"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                         <label>Longitude</label>
                        <v-text-field
                            solo
                            placeholder="Enter longitude"
                            v-model="warehouse.longitude"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <label>Warehouse PIC: </label>
                <v-text-field
                    solo
                    placeholder="Person In Charge Name"
                    v-model="warehouse.pic"
                ></v-text-field>
                <label>Country: </label>
                <v-select
                    solo
                    :items="country.countries"
                    append-icon="mdi-magnify"
                    item-text="country"
                    item-value="country_code"
                    placeholder="Warehouse Country"
                    v-model="warehouse.country"
                    @change="stateFromCountry"
                ></v-select>
                <div v-if="state.length">
                    <label>State</label>
                    <v-select
                        solo
                        :items="state"
                        placeholder="Select State"
                        append-icon="mdi-magnify"
                        v-model="warehouse.state"
                    >    
                    </v-select>
                </div>
                <div v-else-if="!state.length">
                    <label>State/Region/Prefecture</label>
                    <v-text-field
                        solo
                        placeholder="Insert State/Region/Prefecture"
                        v-model="warehouse.state"
                    >    
                    </v-text-field>
                </div>
                <label>Unit: </label>
                <v-text-field
                    solo
                    placeholder="Unit"
                    v-model="warehouse.unit"
                ></v-text-field>
                <label>Status: </label>
                <v-switch
                    v-model="warehouse.status"
                    color="success"
                    :label="warehouse.status == true ? 'Active' : 'Inactive'"
                ></v-switch>
            </v-col>
            <v-col cols="5">
                <label>Tel. Number: </label>
                <v-text-field
                    solo
                    placeholder="Enter warehouse telephone number"
                    :prefix="dial_code + ' |'"
                    :disabled="dial_code == ''"
                    v-model="phone_number"
                ></v-text-field>
                <label>Address: </label>
                <v-textarea
                    solo
                    placeholder="Address"
                    v-model="warehouse.addressLine1"
                ></v-textarea>
                <label>Postal Code: </label>
                <v-text-field
                    solo
                    placeholder="Postal Code"
                    v-model="warehouse.postal_code"
                ></v-text-field>
            </v-col>
      </v-row>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveWarehouse()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ type.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue'
import countrydata from './country/country.json'
export default {
    name: 'WarehouseForm',
    components: {
        Alert
    },
    data() {
        return {
            country: countrydata,
            state : [],
        }
    },
    props: {
        warehouse: {
            type: Object,
            default: () => {
                return {
                    status: true,
                    LocationName: '',
                    latitude: '',
                    longitude: '',
                    addressLine1: '',
                    phone_number: '',
                    postal_code: '',
                    country: '',
                    state: '',
                    unit: '',
                    pic: ''
                }
            }
        },
        type: {
            type: Object,
            default: () => {
                return {
                    text: 'Add New',
                    value: 'new'
                }
            }
        },
        buttonLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        saveWarehouse() {
            this.$emit('saveWarehouse', this.dial_code)
        },
        stateFromCountry(item) {
            this.country.countries.forEach(country => {
                if (country.country_code == item) {
                    this.state = country.states
                    this.dial_code = country.dial_code
                }
            })
        },
    },
    computed: {
        dial_code: {
            get: function() {
                return this.warehouse.country ? this.getDialCode(this.warehouse.country) : ""
            },
            set: function(v) {
                return v;
            }
        },
        phone_number: {
            get: function() {
                return this.warehouse.phone_number ? this.removeCountryCode(this.warehouse.country, this.warehouse.phone_number) : ""
            },
            set: function(v) {
                this.warehouse.phone_number = v
            }
        }
    }
}
</script>

<style>

</style>