var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Promotion List")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Search Customer","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""}})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"2"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-history")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/dashboard/add/promotion"}},[_c('v-btn',{staticClass:"text--futura text--white text-capitalize",attrs:{"dark":"","block":""}},[_vm._v(" Add Promotion ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"justify-space-around crystal-admin-form"},[_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Valid Period")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.valid_period,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.valid_period=$event},"update:return-value":function($event){_vm.valid_period=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To","readonly":"","append-icon":"mdi-calendar"},model:{value:(_vm.valid_period),callback:function ($$v) {_vm.valid_period=$$v},expression:"valid_period"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.valid_period),callback:function ($$v) {_vm.valid_period=$$v},expression:"valid_period"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.valid_period)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('Alert'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.promotions,"items-per-page":5},scopedSlots:_vm._u([{key:"item.promoStatus",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{model:{value:(item.promoStatus),callback:function ($$v) {_vm.$set(item, "promoStatus", $$v)},expression:"item.promoStatus"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.valid_period",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.valid_from))+" - "+_vm._s(_vm.formatDate(item.valid_until)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('DeleteDialog',{attrs:{"message":("Remove Promotion " + (_vm.promotion.promoName) + " ?"),"item":_vm.promotion,"showDelete":_vm.showDelete},on:{"deleteItem":_vm.deletePromotion,"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }