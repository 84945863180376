<template>
    <v-container fluid class="admin-page">
        <v-row class="justify-space-between">
          <v-col
          cols="7"
          >
              <h2>{{ formType.text }} Order</h2>
          </v-col>
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveOrder()" :loading="buttonLoading" :disabled="button">
                  {{ formType.value == 'new' ? 'Save' : 'Update' }}
                  <template v-slot:loader>
                      <v-progress-circular
                          indeterminate
                          color="black"
                      ></v-progress-circular>
                  </template>
              </v-btn>
          </v-col>
        </v-row>
        <v-container fluid class="crystal-admin-form">
          <v-row class="justify-space-around">
                  <v-col cols="5">
                      <label>Customer Name: </label>
                      <v-text-field
                          solo
                          placeholder="Enter Customer name"
                          v-model="order.Customer.fullName"
                      ></v-text-field>
                      <label>Shipment Address: </label>
                      <v-textarea
                          solo
                          placeholder="Shipment Address"
                          v-model="order.ShiptoAddress"
                      ></v-textarea>
                      <label>Shipping Flow</label>
                      <v-select
                          :items="shippingFlowList"
                          solo
                          label="Choose Flow"
                          v-model="order.shippingFlow"
                          @change="getDeliveryFee"
                      >
                      </v-select>
                      <div v-show="order.shippingFlow === 'manual'">
                          <label>Form Manual Shipping</label>
                          <hr :style="{'marginBottom':'20px'}"/>
                          <v-select
                              :items="courierList"
                              solo
                              label="Choose Courier"
                              item-text="CourierCompany"
                              item-value="id"
                              v-model="order.DeliveryMethodId"
                          ></v-select>
                          <v-text-field
                              solo
                              placeholder="Courier Tracking Code"
                              v-model="order.receiptNumber"
                          >
                          </v-text-field>
                          <v-text-field
                              solo
                              placeholder="Enter Delivery Fee"
                              v-model="order.delivery_fee"
                          >
                          </v-text-field>
                          <hr :style="{'marginBottom':'20px'}" />
                      </div> 
                      <label>Country</label>
                      <v-select
                          solo
                          :items="country.countries"
                          placeholder="Select Country"
                          append-icon="mdi-magnify"
                          item-text="country"
                          item-value="country_code"
                          @change="stateFromCountry"
                          v-model="order.country"
                      >    
                      </v-select>
                      <div v-if="state.length">
                          <label>State</label>
                          <v-select
                              solo
                              :items="state"
                              placeholder="Select State"
                              append-icon="mdi-magnify"
                              v-model="order.state"
                          >    
                          </v-select>
                      </div>
                      <div v-else-if="!state.length">
                          <label>State/Region/Prefecture</label>
                          <v-text-field
                              solo
                              placeholder="Insert State/Region/Prefecture"
                              v-model="order.state"
                          >    
                          </v-text-field>
                      </div>
                      <label>Unit: </label>
                      <v-text-field
                          solo
                          placeholder="Unit"
                          v-model="order.unit"
                      ></v-text-field>
                      <label>Postal Code: </label>
                      <v-text-field
                          solo
                          placeholder="Postal Code"
                          v-model="order.postal_code"
                          @change="order.shippingFlow === 'easy_parcel' && getDeliveryFee"
                          :rules="[postcodeRule]"
                      ></v-text-field>
                      <label>Customer Contact</label>
                      <v-text-field
                          solo
                          placeholder="Enter Customer Phone Number"
                          :prefix="dial_code + ' |'"
                          v-model="orderContact"
                          :disabled="dial_code == ''"
                      ></v-text-field>
                      <!-- <label>Courier Tracking Code: </label>
                      <v-text-field
                          solo
                          placeholder="Courier Tracking Code"
                      ></v-text-field> -->
                      <!-- <label>AR Account: </label>
                      <v-text-field
                          solo
                          placeholder="Enter AR Account"
                      ></v-text-field> -->
                      <v-row class="justify-space-between align-center">
                          <v-col cols="3">
                              <label>Dropshipper</label>
                          </v-col>
                          <v-col cols="4">
                              <v-checkbox
                                  v-model="dropshipper_check"
                              >
                              </v-checkbox>
                          </v-col>
                      </v-row>
                      <div
                          v-if="dropshipper_check"
                      >
                          <label>Dropshipper Name:</label>
                          <v-text-field 
                              solo
                              placeholder="Enter Name"
                          >
                          </v-text-field>
                          <label>Dropshipper Contact:</label>
                          <v-text-field 
                              solo
                              placeholder="Enter phone number"
                              prefix="+65 |"
                          >
                          </v-text-field>
                      </div>
                  </v-col>
                  <v-col cols="5">
                      <label>Order Date: </label>
                      <v-row>
                          <v-col
                          cols="12"
                          >
                              <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="order_date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="350px"
                              >
                                  <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="order_date"
                                      label="Order Date"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      solo
                                      append-icon="mdi-calendar"
                                  ></v-text-field>
                                  </template>
                                  <v-date-picker
                                  v-model="order_date"
                                  no-title
                                  scrollable
                                  >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      text
                                      color="primary"
                                      @click="menu = false"
                                  >
                                      Cancel
                                  </v-btn>
                                  <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(order_date)"
                                  >
                                      OK
                                  </v-btn>
                                  </v-date-picker>
                              </v-menu>
                          </v-col>
                      </v-row>
                      <label>Ship From</label>
                      <v-autocomplete
                          solo
                          :items="warehouses"
                          placeholder="Warehouse Location"
                          append-icon="mdi-magnify"
                          item-text="LocationName"
                          item-value="id"
                          v-model="order.ShipFromLocationId"
                      >    
                      </v-autocomplete>
                      <label>Sales Type:</label>
                      <v-select
                          :items="sales_types"
                          solo
                          label="Choose Type"
                          v-model="order.sales_type"
                      >
                      </v-select>
                      <label>Customer Type:</label>
                      <v-select
                          :items="customer_types"
                          solo
                          label="Choose Type"
                          v-model="order.customer_type"
                      >
                      </v-select>
                      <label>External Invoice Number:</label>
                      <v-text-field
                          solo
                          placeholder="Enter Invoice Number"
                          v-model="order.invoice_no"
                      >
                      </v-text-field>
                      <label>Memo:</label>
                      <v-text-field
                          solo
                          placeholder="Enter Memo"
                          v-model="order.memo"
                      >
                      </v-text-field>
                      <label>Shipment Status: </label>
                      <v-select
                          solo
                          :items="shipment_statuses"
                          item-text="label" 
                        item-value="value"
                          label="Choose Status"
                          v-model="order.ShipStatus"
                      ></v-select>
                      <label>Payment Status: </label>
                      <v-select
                          solo
                          :items="order_statuses"
                          label="Choose Status"
                          v-model="order.OrderStatus"
                      ></v-select>
                  </v-col>
          </v-row>
          <hr>
          <v-container>
              <ProductOrder
                  v-for="(product, index) in order.OrderLine.items"
                  :key="product.id"
                  :index="index"
                  :product="product"
                  :formType="formType"
                  @deleteProduct="deleteProduct"
                  @updateProduct="updateProduct"
              />
          </v-container>
          <v-row class="justify-center">
              <v-col cols="2">
                  <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="addProduct()">
                      + Add Product
                  </v-btn>
              </v-col>
          </v-row>
          <hr>
          <v-row class="flex-column align-end">
              <v-col cols="4">
                  <span>Total Quantity : {{ quantity }}</span>
              </v-col>
              <v-col cols="4">
                  <span>Sub Total : {{ formatHarga(price_total) }}</span>
              </v-col>
              <v-col cols="4">
                  <span>Tax <v-select v-model="order.tax" dense :items="taxes" item-value="value" item-text="text" autowidth></v-select> : {{ tax }}</span>
              </v-col>
              <v-col cols="4">
                  <span>Estimation Delivery Fee :  <small>{{msg}}</small> <v-text-field v-model="deliveryFee"></v-text-field> </span>
              </v-col>
              <v-col cols="4" v-if="formType.value != 'new'">
                  <span>Coupon Discount :  <v-text-field disabled v-model="order.discount"></v-text-field> </span>
              </v-col>
              <v-col cols="4">
                  <span><b>Total : {{ formatHarga(grand_total) }}</b></span>
              </v-col>
          </v-row>
        </v-container>
        <v-row class="justify-end">
            <v-col cols="2">
                <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveOrder()" :loading="buttonLoading" :disabled="button">
                  {{ formType.value == 'new' ? 'Save' : 'Update' }}
                  <template v-slot:loader>
                      <v-progress-circular
                          indeterminate
                          color="black"
                      ></v-progress-circular>
                  </template>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  import ProductOrder from '@/components/ProductOrder.vue'
  import countrydata from './country/country.json'
  import server from '../api'
  
  export default {
      name: 'OrderForm',
      components: {
          ProductOrder
      },
      props: {
          formType: {
              type: Object,
              default: () => {
                  return {
                      text: 'Add New',
                      value: 'new'
                  }
              }
          },
          buttonLoading: {
              type: Boolean,
              default: false
          },
          order: {
              type: Object,
              default: () => {
                  return {
                      postal_code: '',
                      ShiptoAddress: '',
                      ShipStatus: '',
                      OrderStatus: '',
                      ShipFromLocationId: '',
                      order_date: new Date().toISOString().substr(0, 10),
                      shipment_date: null,
                      sales_type: '',
                      customer_type: '',
                      Customer: {
                          fullName: '',
                          CustomerContact: {
                              contactNo: '',
                              unit: '',
                              state: '',
                              country: '',
                              dial_code: '',
                          }
                      },
                      OrderLine: {
                          items: []
                      },
                      tax: 0,
                      delivery_fee: 0,
                      memo: '',
                      invoice_no: '',
                      shippingFlow: "manual",
                      DeliveryMethodId: 0,
                      receiptNumber: ""
                  }
              }
          },
      },
      data () {
          return {
              country: countrydata,
              button: false,
              postcodeErr: "",
              msg: "",
              state : [],
              taxes: [
                  { text: '0%', value: 0 },
                  { text: '5 %', value: 5},
                  { text: '10 %', value: 10},
                  { text: '20 %', value: 20},
                  { text: '30 %', value: 30}
              ],
              dropshipper_check: false,
              menu: false,
              menu2: false,
              selectedTax: 10,
              couriers: [],
              shippingFlowList: [
                  {
                      text: "Easy Parcel",
                      value: "easy_parcel"
                  },
                  {
                      text: "Manual",
                      value: "manual"
                  },
              ],
          }
      },
      methods: {
          postcodeRule() {
              if (this.order.country == '') {
                  this.button = true
                  return 'Please fill country first'
              } else {
                  this.button = false
                  return true
              }
          },
          getDeliveryFee() {
              this.button = true
              setTimeout(() => {
                  this.msg = "Calculating fee..."
              }, 500)
              server.get(`/delivery/couriers/cheapest?send_code=${this.order.postal_code}&send_country=${this.order.country}&service_detail_filter=pickup`)
              .then(res => {
                  this.button = false
                  this.msg = ""
                  this.deliveryFee = res.data.cheapest_fee
              })
          },
          stateFromCountry(item) {
              this.country.countries.forEach(country => {
                  if (country.country_code == item) {
                      this.state = country.states
                      this.dial_code = country.dial_code
                  }
              })
          },
          saveOrder() {
              this.$emit('saveOrder', {grand_total: this.grand_total, dial_code: this.dial_code})
          },
          addProduct () {
              let newProduct = {
                  ItemId: '',
                  qty: '',
                  price: '',
                  Item: {
                      name: ''
                  }
              }
              this.order.OrderLine.items.push(newProduct)
          },
          deleteProduct(index) {
              this.order.OrderLine.items.splice(index, 1)
          },
          updateProduct (index, id, qty, price) {
              this.order.OrderLine.items[index].ItemId = id
              this.order.OrderLine.items[index].qty = qty
              this.order.OrderLine.items[index].price = price
          },
      },
      computed: {
          dial_code: {
              get: function() {
                  return this.order.country ? this.getDialCode(this.order.country) : ""
              },
              set: function(v) {
                  return v;
              }
          },
          CourierSelect: {
              get: function () {
                  return this.order.DeliveryHistory ? this.order.DeliveryHistory.serviceId : ""
              },
              set: function(value) {
                  this.order.DeliveryHistory.serviceId  = value
              }
          },
          deliveryFee: {
              get: function() {
                  return this.order.delivery_fee
              },
              set: function(value) {
                  this.order.delivery_fee = value
              }
          },
          order_statuses () {
              return this.$store.state.order_statuses;
          },
          sales_types () {
              return this.$store.state.sales_types;
          },
          shipment_statuses () {
              return this.$store.state.shipment_statuses;
          },
          courierList() {
              return this.$store.state.deliveryMethods
          },
          orderContact: {
              get () {
                  return this.removeCountryCode(this.order.country, this.order.Customer.CustomerContact.contactNo);
              },
              set (value) {
                  this.order.Customer.CustomerContact.contactNo = value;
              }
          },
          quantity () {
              let quantity = 0;
              for(let i = 0; i < this.order.OrderLine.items.length; i++) {
                  quantity += +this.order.OrderLine.items[i].qty
              }
              return quantity
          },
          products () {
              return this.$store.state.products
          },
          tax () {
              return ((this.order.tax * 0.01) * this.price_total)
          },
          price_total () {
              let count = 0;
              let price_total = 0;
              for(let i = 0; i < this.order.OrderLine.items.length; i++) {
                  let item_price
                  if (this.formType.text == "Edit") {
                      item_price = this.order.OrderLine.items[i].price
                  } else {
                      item_price = this.order.OrderLine.items[i].price ? this.order.OrderLine.items[i].price : this.order.OrderLine.items[i].Item.retail_price;
                  }
                  count = this.order.OrderLine.items[i].qty * item_price;
                  price_total += count
                  count = 0
              }
              return price_total
          },
          grand_total () {
              //return this.price_total - (this.order.discount ? this.order.discount : 0) + this.tax + (+this.deliveryFee)
              return this.price_total + this.tax + (+this.deliveryFee)
          },
          // couriers () {
          //     return this.$store.state.couriers
          // },
          customer_types () {
              return this.$store.state.customer_types
          },
          warehouses () {
              return this.$store.state.warehouses
          },
          shipment_date: {
              get: function () {
                  return this.order.DeliveryHistory ? this.formatDate(this.order.DeliveryHistory.collectDate, 'YYYY-M-D') : '';
              },
              set: function (value) {
                  this.order.shipment_date = this.formatDate(value, 'YYYY-M-D')
              }
          },
          order_date: {
              get: function () {
                  return this.order.order_date ? this.formatDate(this.order.order_date, 'YYYY-MM-DD') : this.formatDate(new Date(), 'YYYY-MM-DD');
              },
              set: function (value) {
                  this.order.order_date = value
              }
          }
      },
      created () {
          this.$store.dispatch('getProducts')
          this.$store.dispatch('getDeliveryMethods')
          this.$store.dispatch('getWarehouses')
      },
  }
  </script>
  
  <style>
  
  </style>